import React from 'react';
import { colors } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CodeIcon from '@material-ui/icons/Code';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import BookIcon from '@material-ui/icons/BookOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ChatIcon from '@material-ui/icons/Chat';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlotOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import MapIcon from '@material-ui/icons/Map';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { Label } from 'components';
import translate from 'translate';
import ClassIcon from '@material-ui/icons/Class';
import KitchenIcon from '@material-ui/icons/Kitchen';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import BusinessIcon from '@material-ui/icons/Business';
import StoreIcon from '@material-ui/icons/Store';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import AssessmentIcon from '@material-ui/icons/Assessment';
import StarsIcon from '@material-ui/icons/Stars';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import RedeemIcon from '@material-ui/icons/Redeem';
import HelpIcon from '@material-ui/icons/Help';

export default [
  {
    title: translate(''),
    pages: [
      {
        title: 'Inicio',
        href: '/overview',
        capability: 'none',
        icon: DashboardIcon
      },
      {
        title: translate('Usuarios'),
        href: '/management/customers',
        capability: 'manage_users',
        icon: PersonOutlineIcon,
      },
      {
        title: translate('Administradores'),
        href: '/management/administrators',
        capability: 'manage_admins',
        icon: SupervisorAccountIcon,
      },
      {
        title: translate('Roles'),
        href: '/management/roles',
        capability: 'manage_roles',
        icon: VpnKeyIcon,
      },
      {
        title: translate('Conductores'),
        href: '/management/drivers',
        capability: 'manage_drivers',
        icon: MotorcycleIcon,
      },
      // {
      //   title: translate('Empleados'),
      //   href: '/management/employees',
      // capability: '',
      //   icon: PersonIcon,
      // },
      {
        title: translate('Categorías'),
        href: '/management/categories',
        capability: 'manage_categories',
        icon: ClassIcon,
      },
      // {
      //   title: translate('Categorías de Productos'),
      //   href: '/management/categories',
      // capability: '',
      //   icon: ClassIcon,
      // },
      // {
      //   title: translate('Centros de Costo'),
      //   href: '/management/centers',
      // capability: '',
      //   icon: StoreIcon,
      // },
      {
        title: translate('Restaurantes'),
        href: '/management/restaurants',
        capability: 'manage_restaurants',
        icon: StoreIcon,
      },
      {
        title: 'Ordenes',
        href: '/management/orders',
        capability: 'manage_orders',
        icon: ListAltIcon,
      },
      {
        title: 'Mapa',
        href: '/management/maps',
        capability: 'manage_maps',
        icon: MapIcon,
      },
      {
        title: translate('Empresas'),
        href: '/management/companies',
        capability: 'manage_companies',
        icon: BusinessIcon,
      },
      // {
      //   title: translate('Productos'),
      //   href: '/management/products',
      // capability: '',
      //   icon: ListAltIcon,
      // },
      // {
      //   title: translate('Ingredientes'),
      //   href: '/management/ingredients',
      // capability: '',
      //   icon: ListAltIcon,
      // },
      // {
      //   title: translate('Ingredientes'),
      //   href: '/management/products',
      // capability: '',
      //   icon: ListAltIcon,
      // },
      // {
      //   title: translate('Moléculas'),
      //   href: '/management/substances',
      // capability: '',
      //   icon: ScatterPlotIcon,
      // },
      // {
      //   title: translate('Documentos'),
      //   href: '/management/documents',
      // capability: '',
      //   icon: DescriptionIcon,
      // },
      // {
      //   title: translate('Store')+'s',
      //   href: '/management/stores',
      // capability: '',
      //   icon: StoreMallDirectoryIcon,
      // },
      {
        title: 'Cupones',
        href: '/management/coupons',
        capability: 'manage_coupons',
        icon: ConfirmationNumberIcon,
      },
      {
        title: 'Promociones',
        href: '/management/promotions',
        capability: 'manage_promotions',
        icon: LocalActivityIcon,
      },
      {
        title: 'Banners',
        href: '/management/banners',
        capability: 'manage_banners',
        icon: ViewCarouselIcon,
      },
      {
        title: 'Banners Rider',
        href: '/management/bannersrider',
        capability: 'manage_driver_banners',
        icon: ViewCarouselIcon,
      },
      {
        title: 'Populares',
        href: '/management/featureds',
        capability: 'manage_populars',
        icon: WhatshotIcon,
      },
      // {
      //   title: 'Cocina',
      //   href: '/dashboards/kitchen',
      // capability: '',
      //   icon: KitchenIcon,
      // },
      {
        title: 'Notificaciones',
        href: '/projects/create',
        capability: 'manage_notifications',
        icon: NotificationsNoneIcon,
      },
      {
        title: 'Mensajes',
        href: '/management/claims',
        capability: 'manage_messages',
        icon: ChatIcon,
      },

      // manage_admin_restaurants
      {
        title: 'Menu',
        href: '/settingsmenu/categories',
        capability: 'manage_admin_restaurants',
        icon: RestaurantIcon,
      },
      {
        title: 'Reportes',
        href: '/settingsreportes/general',
        capability: 'manage_admin_restaurants',
        icon: AssessmentIcon,
      },
      {
        title: 'Valoraciones',
        href: '/settingsvaloraciones/general',
        capability: 'manage_admin_restaurants',
        icon: StarsIcon,
      },
      {
        title: 'Pagos',
        href: '/settingspagos/general',
        capability: 'manage_admin_restaurants',
        icon: LocalAtmIcon,
      },
      {
        title: 'Restaurante',
        href: '/settingsrestaurant/general',
        capability: 'manage_admin_restaurants',
        icon: StoreIcon,
      },
      {
        title: 'Horarios',
        href: '/settingshorarios/general',
        capability: 'manage_admin_restaurants',
        icon: QueryBuilderIcon,
      },
      {
        title: 'Marketing',
        href: '/settingsmarketing/general',
        capability: 'manage_admin_restaurants',
        icon: RedeemIcon,
      },
      {
        title: 'Centro de Ayuda',
        href: '/settingsayuda/general',
        capability: 'manage_admin_restaurants',
        icon: HelpIcon,
      },
    ]
  },
];
