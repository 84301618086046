const DeliveryStatus = (status) => {
  switch (status) {
    case -4: return 'Cancelado, por tiempo de espera';
    case -3: return 'Cancelado, Usuario no esta o ya no quiere el pedido';
    case -2: return 'Cancelado, Driver no pudo entregar el pedido';
    case -1: return 'Cancelado, por el restaurant';
    case 0: return 'Pendiente de pago';
    case 1: return 'Creado';
    case 2: return 'Aceptado por el restaurant';
    case 3: return 'Preparando pedido';
    case 4: return 'Driver asignado y en camino al restaurant';
    case 5: return 'Driver llego al restaurant';
    case 6: return 'Driver tiene pedido y va a tu casa';
    case 7: return 'Driver llego a tu casa';
    case 8: return 'Driver entrego el pedido';
    case 9: return 'Finalizado';
    default: return status
  }

}

export default DeliveryStatus;