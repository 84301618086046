import React, { useState } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// const Accordion = withStyles({
//   root: {
//     '&$expanded': {
//       margin: 'auto',
//     },
//   },
//   expanded: {},
// })(MuiAccordion);

const Accordion = ({ header, children}) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = (event, newExpanded) => {
    setExpanded(newExpanded);
  }

  return (
    <MuiAccordion
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<ExpandMoreIcon />}
        id="panel1a-header"
      >
        {header}
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </MuiAccordion>
  )
}

export default Accordion;
