import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// import 'firebase/messaging';
import 'firebase/storage';
import {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId
} from 'config';

// console.log(process.env);

const options = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};

firebase.initializeApp(options);

firebase.onLog((args, lvl, message, type) => {
  console.log({args, lvl, message, type})
})

export default firebase;