import firebase from 'utils/firebase';
// import init from '../reducers/index';
export const UPDATE_DRIVERS = 'UPDATE_DRIVERS';
export const UPDATE_DRIVER = 'UPDATE_DRIVER';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const UPDATE_POLYGONS = 'UPDATE_POLYGONS';
export const UPDATE_RESTAURANTS = 'UPDATE_RESTAURANTS';
export const UPDATE_PROGRESSSAVEJSON = 'UPDATE_PROGRESSSAVEJSON';
export const UPDATE_DRIVER_CURRENT_COORD = 'UPDATE_DRIVER_CURRENT_COORD';
export const UPDATE_SELECTED_TAB = 'UPDATE_SELECTED_TAB';
export const UPDATE_PROGRESSDELETEJSON = 'UPDATE_PROGRESSDELETEJSON';
export const ISVISIBLEPOLYGON = 'ISVISIBLEPOLYGON';
export const UPDATE_DRIVER_ACTIVES = 'UPDATE_DRIVER_ACTIVES'
export const GET_DRIVER_ACTIVES ='GET_DRIVER_ACTIVES'
export const UPDATE_COUNTDRIVERSPECIAL ='UPDATE_COUNTDRIVERSPECIAL'
export const UPDATE_CURRENTDATEDRIVER ='UPDATE_CURRENTDATEDRIVER'
export const updateSelectTab = value => {
  return async function(dispatch) {
    dispatch({ type: UPDATE_SELECTED_TAB, payload: value });
  };
};

export const updateIsVisiblePolygon = id => {
  return async function(dispatch) {
    dispatch({ type: ISVISIBLEPOLYGON, payload: id });
  };
};
export const update_current_date_driver = date => {

  return async function(dispatch) {
    console.log('ña fecha que llega al actions',date)
    dispatch({ type: UPDATE_CURRENTDATEDRIVER, payload: date });
  };
};
export const fetchDrivers = () => {
  return async function(dispatch) {
    let ref = await firebase
      .firestore()
      .collection('drivers')
      .where('online', '==', true)
      .where('driverLocation', '!=', null)
      .onSnapshot(function(snapshot) {
        var drivers = [];
        var driversActives = [];
        snapshot.forEach(function(doc) {
          if(doc.data().active){
            //console.log('estado de cada driver',doc.data().active)
            driversActives.push(doc.data());
          }
          drivers.push(doc.data());
        });

        dispatch({ type: UPDATE_DRIVERS, payload: drivers });
        dispatch({ type: GET_DRIVER_ACTIVES, payload: driversActives });
      })

  };
};
export const fetchPolygons = () => {
  return async function(dispatch) {
    let ref = await firebase
      .firestore()
      .collection('polygons')
      .onSnapshot(function(snapshot) {
        var polygons = [];
        snapshot.forEach(function(doc) {
          polygons.push(Object.assign(doc.data(), { show: true, id: doc.id }));
        });

        dispatch({ type: UPDATE_POLYGONS, payload: polygons });
      });
  };
};
export const update_process_save_json = () => {
  return function(dispatch) {
    dispatch({
      type: UPDATE_PROGRESSSAVEJSON,
      payload: 0
    });
  };
};
export const update_process_delete_json = () => {
  return function(dispatch) {
    dispatch({
      type: UPDATE_PROGRESSSAVEJSON,
      payload: 0
    });
  };
};
export const SavePolygons = newPolygons => {
  return function(dispatch) {
    let total_p = newPolygons.length;

    let porcentage = 100 / total_p;
    let count_progress = 0;
    let count = 0;
    newPolygons.map(async polygon => {
      let coordFormated = [];
      polygon.coordinates.map((coord, index) => {
        coordFormated.push({ lat: coord[1], lng: coord[0] });
      });

      await firebase
        .firestore()
        .collection('polygons')
        .add({
          name: polygon.name,
          fill: polygon['fill'],
          'fill-opacity': polygon['fill-opacity'],
          stroke: polygon['stroke'],
          'stroke-opacity': polygon['stroke-opacity'],
          'stroke-width': polygon['stroke-width'],
          styleHash: polygon['styleHash'],
          coordinates: coordFormated
        })
        .then(function(docRef) {
          count++;
          count_progress = count_progress + porcentage;
          dispatch({
            type: UPDATE_PROGRESSSAVEJSON,
            payload: count_progress
          });
        })
        .catch(function(error) {
          console.error('Error adding document: ', error);
        });
    });

    fetchPolygons();
  };
};
export const fetchOrders = () => {
  return async function(dispatch) {
    let ref = await firebase
      .firestore()
      .collection('orders')
      .where('createdAt', '!=', null)
      .onSnapshot(function(snapshot) {
        var drivers = [];
        snapshot.forEach(function(doc) {
          drivers.push(doc.data());
        });

        dispatch({ type: UPDATE_ORDERS, payload: drivers });
      });
  };
};

export const DeletePolygons = () => {
  return async function(dispatch) {
    let ref = firebase.firestore().collection('polygons');
    await firebase
      .firestore()
      .collection('polygons')
      .get()
      .then(function(querySnapshot) {
        const total = querySnapshot.size;

        let porcentage = 100 / total;
        let count_progress = 0;

        querySnapshot.forEach(function(doc) {
          ref
            .doc(doc.id)
            .delete()
            .then(function() {
              count_progress = count_progress + porcentage;
              dispatch({
                type: UPDATE_PROGRESSDELETEJSON,
                payload: count_progress
              });
            })
            .catch(error => {
              console.log(error, 'error eliminando');
            });
        });
      })

      .catch(function(error) {
        console.log('Error getting documents: ', error);
      });
    dispatch({
      type: UPDATE_PROGRESSDELETEJSON,
      payload: 0
    });
    // console.log('eliminacion terminada');
    return true;
  };
};

export const updatedrivercurrentcoord = coord => {
  return async function(dispatch) {
    dispatch({ type: UPDATE_DRIVER_CURRENT_COORD, payload: coord });
  };
};

export const updatefecthDriversActives = () => {
  return async function(dispatch) {
    dispatch({ type: UPDATE_DRIVER_ACTIVES});
  };
}
export const updatefecthDrivers = () => {
  return async function(dispatch) {
    let result = [];
    let ref = await firebase
      .firestore()
      .collection('drivers')
      .where('driverLocation', '!=', null)
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          if (change.type === 'added') {
            console.log('New driver: ', change.doc.data());
            // dispatch({ type: GET_DRIVER_ACTIVES, payload: result });
            // fetchDrivers()
            update_driver_count()
          }
          if (change.type === 'modified') {
            console.log('Modified driver: ', change.doc.id);
            dispatch({ type: UPDATE_DRIVERS, payload: change.doc.id });
            dispatch(update_driver_count())
          }
          if (change.type === 'removed') {
            fetchDrivers()
            console.log('Removed driver: ', change.doc.data());
          }
        });
      });
  };
};
export const update_driver_count = () => {

  return async function (dispatch){
    // console.log('update_driver_count')
    let ref = await firebase
      .firestore()
      .collection('drivers')
      .where('driverLocation', '!=', null)
      .where('online', '==', true)
      .onSnapshot(function(snapshot) {
        var count = 0;
        snapshot.forEach(function(doc) {
          // console.log('update_driver_count')
          count=count+1
        });

        dispatch({ type: UPDATE_COUNTDRIVERSPECIAL, payload: count  });
      });
  }

  // return async function (dispatch) {
  //   await dispatch({ type: UPDATE_DRIVERS, payload: change.doc.id });
  // }
}
export const fetchRestaurants = () => {
  return async function(dispatch) {
    let ref = await firebase
      .firestore()
      .collection('restaurants')
      .where('location', '!=', null)
      .onSnapshot(function(snapshot) {
        var rest = [];
        snapshot.forEach(function(doc) {
          rest.push(doc.data());
        });

        dispatch({ type: UPDATE_RESTAURANTS, payload: rest });
      });
  };
};