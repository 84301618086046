import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, connect, useSelector } from 'react-redux';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Input,
  colors,
  Popper,
  Paper,
  List,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import PhoneIcon from '@material-ui/icons/Phone';
import LockIcon from '@material-ui/icons/LockOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import firebase from 'utils/firebase';
import axios from 'utils/axios';
// import useRouter from 'utils/useRouter';
import { PricingModal, NotificationsPopover } from 'components';
import moment from 'moment';
import translate from 'translate';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Help from '../Help';
import { withRouter } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { login, logout, update } from 'actions';
import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { CustomThemeContext } from 'theme/CustomThemeProvider';
// import IOSSwitch from 'components/IOSSwitch';
//import 'fontsource-roboto';

const t = translate;
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#573A90'
    // #64D3DE
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    color: 'white',
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  select: {
    borderColor: '#ffffff',
    '&:before': {
      borderColor: '#ffffff',
    },
    '&:after': {
      borderColor: '#ffffff',
    }
  },
  icon: {
    fill: '#ffffff',
  },
}));


const TopBar = props => {
  const { onOpenNavBarMobile, className, staticContext, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  // const searchRef = useRef(null);
  const dispatch = useDispatch();
  const notificationsRef = useRef(null);
  // const [searchValue, setSearchValue] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  // const [open, setOpen] = useState(false);
  const [newNotifications, setNewNotifications] = useState('');
  // const [state, setState] = useState({ checkedA: false, checkedB: true });
  // const session = useSelector(state => state.session);
  // const [pedidos, setPedidos] = useState([]);
  // const [timer, setTimer] = useState(0);
  // const [countdown, setCountdown] = useState('03:00');
  // const url = '/musica/bicci-sound.wav';
  // const [audio, setAudio] = useState(new Audio(url));
  // const cboRestaurants = session.user.restaurantes;
  // const restaurantID = session.user.restaurantID;
  // const [anchorEl, setAnchorEl] = useState(null);
  // const opened = Boolean(anchorEl);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const [formState, setFormState] = useState({
  //   isValid: false,
  //   values: {},
  //   touched: {},
  //   errors: {},
  //   restaurantID: restaurantID,
  //   restaurantName: ''
  // });

  // const handleChangeRestaurant = async event => {
  //   event.persist();
  //   setFormState(formState => ({
  //     ...formState,
  //     'restaurantID': event._targetInst.memoizedProps.value,
  //     'restaurantName': event._targetInst.memoizedProps.children[0][0],
  //     'isValid': event.target.value !== '' ? true : false
  //   }));

  //   const params = { restaurantID: event._targetInst.memoizedProps.value, restaurantName: event._targetInst.memoizedProps.children[0][0] };
  //   dispatch(update(params));
  //   history.push('/');
  // }

  // const handleSavePedido = () => {
  //   console.log(pedidos);
  //   pedidos.forEach(item => {
  //     firebase.firestore().collection('orders').doc(item).set({ status: 2 }, { merge: true });
  //     console.log('Aprobado: ' + item);
  //   });
  //   setOpen(false);
  //   setCountdown('03:00');
  //   clearInterval(timer);
  //   //localStorage.setItem("uso", false);
  //   localStorage.removeItem('uso');
  //   //fetchDataOrden();
  //   setPedidos([]);

  // }

  // const handleStatus = () => {
  //   console.log(pedidos);
  //   pedidos.forEach(item => {
  //     firebase.firestore().collection('orders').doc(item).set({ status: -1 }, { merge: true });
  //     console.log('Rechazado: ' + item);
  //   });
  //   // audio.pause();
  //   // audio.currentTime = 0;
  //   setOpen(false);
  //   setCountdown('03:00');
  //   clearInterval(timer);
  //   localStorage.removeItem('uso');
  //   setPedidos([]);

  // }

  // const contador = () => {
  //   let audio = new Audio(url);
  //   setTimeout(() => {
  //     // audio.play()
  //   }, 3000)


  //   var duration = moment.duration({
  //     'minutes': 3,
  //     'seconds': 0

  //   });

  //   //var timestamp = new Date(0, 0, 0, 2, 10, 30);
  //   var interval = 1;
  //   const timer = setInterval(() => {
  //     setTimer(timer);
  //     //console.log('This will run every second!');
  //     // timestamp = new Date(timestamp.getTime() + interval * 1000);

  //     duration = moment.duration(duration.asSeconds() - interval, 'seconds');
  //     var min = duration.minutes();
  //     var sec = duration.seconds();

  //     sec -= 1;
  //     if (min < 0) return clearInterval(timer);
  //     if (min < 10 && min.length != 2) min = '0' + min;
  //     if (sec < 0 && min != 0) {
  //       min -= 1;
  //       sec = 59;
  //     } else if (sec < 10 && sec.length != 2) sec = '0' + sec;

  //     setCountdown(min + ':' + sec);

  //     if (min == 0 && sec == 0) {
  //       clearInterval(timer);
  //       console.log('Listo por ahora');
  //       handleStatus();
  //       setOpen(false)
  //     }

  //   }, 1000);

  //   return () => clearInterval(interval);
  // }

  // useEffect(() => {
  //   let mounted = true;
  //   (async function () {
  //     if (session.loggedIn == true) {
  //       localStorage.removeItem('uso');
  //       //let doc = firebase.firestore().collection('orders').where('status', '==', 1);
  //       let doc = firebase.firestore().collection('orders').where('status', '==', 1).where('scheduled', '==', false).where('placeID', '==', restaurantID);
  //       const observer = doc.onSnapshot(docSnapshot => {
  //         let data = [];
  //         setTimeout(() => {
  //           docSnapshot.docChanges().forEach(function (change) {

  //             if (change.type === 'added') {
  //               console.log('New order: ', change.doc.data());
  //               data.push(change.doc.data().id);
  //             }

  //           });
  //           if (data.length > 0 && open != true) {
  //             //setOpen(true);
  //             //descomentar audio para cocina
  //             //audio.play()
  //             // setOpen(true);
  //             setPedidos(pedidos => [...pedidos].concat(data));
  //             // if (localStorage.getItem('uso') != "true") {
  //             //   localStorage.setItem("uso", true);
  //             // //  contador();
  //             // }
  //           }
  //         }, 3000);
  //       }, err => {
  //         console.log(`Encountered error: ${err}`);
  //       });
  //     }
  //   })();
  //   return () => {
  //     mounted = false;
  //   };
  // }, []);


  // useEffect(() => {

  //   const actualizar = async () => {
  //     //Actualizando registros
  //     let datos = [];
  //     let inicio = firebase.firestore.Timestamp.fromDate(new Date('2020-09-04 08:54:03'));
  //     let ref = await firebase.firestore().collection('notifications').where('date', '>=', inicio).orderBy('date', 'desc')
  //       .get();
  //     let registro = ref.docs.map(d => { return d.data() });
  //     for (var i = 0; i < registro.length; i++) {
  //       //   console.log(registro);
  //       if (registro[i].date) {
  //         registro[i]['fecha'] = moment(registro[i].date.toDate());
  //         datos.push(registro[i]);
  //       }
  //     }
  //     setNotifications(datos);
  //     //Actualizando registros
  //   }

  //   let mounted = true;

  //   let ordersdoc = firebase.firestore().collection('orders');

  //   ordersdoc.onSnapshot(async docSnapshot => {
  //     let data = [];
  //     const registros = await docSnapshot.docChanges();
  //     for (let index = 0; index < registros.length; index++) {
  //       let element = registros[index];
  //       //console.log(element);
  //       if (element.type === 'added') {
  //         data = {
  //           id: element.doc.data().id,
  //           title: 'New order ' + element.doc.data().id + ' is registered',
  //           type: 'order',
  //           case: 'registered',
  //           date: firebase.firestore.FieldValue.serverTimestamp()
  //         };
  //         if (element.doc.data().id !== undefined) {
  //           let res = await firebase.firestore().collection('notifications').where('id', '==', element.doc.data().id).get();
  //           let todo = await res.docs.map(item => item.data());
  //           if (todo.length < 1) {
  //             console.log(element.doc.data());
  //             await firebase.firestore().collection('notifications').add(data);
  //           }
  //         }

  //         //await firebase.firestore().collection('notifications').add(data);
  //       }

  //     }


  //     actualizar();

  //   }, err => {
  //     console.log(`Encountered error: ${err}`);
  //   });

  //   //firebase.firestore().collection("users").onSnapshot(() => { });

  //   return () => {
  //     mounted = false;
  //   };
  // }, []);

  // const handleChange = async (event) => {
  //   let estado = !event.target.checked;
  //   setState({ ...state, [event.target.name]: !event.target.checked });
  //   let ref = await firebase.firestore().collection('restaurants').where('admin', '==', session.user.id).get();
  //   let result = await ref.docs.map(item => { return item.data() });
  //   await firebase.firestore().collection('restaurants').doc(result[0].id).set({ offline: estado }, { merge: true });
  //   console.log(estado ? 'Modo offline' : 'Modo Online');
  // };

  // const handleOpen = event => {
  //   setOpen(true);
  // };

  const { currentTheme, setTheme } = useContext(CustomThemeContext);
  const isDark = Boolean( currentTheme === 'dark' );

  const handleThemeChange = (e) => {
    setTheme( e.target.checked ? 'dark' : 'light' );
  }

  const handleLogout = () => {
    history.push('/auth/login');
    dispatch(logout());
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
    setNewNotifications('');
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  // console.log(location.pathname);

  // const hasError = field => formState.touched[field] && formState.errors[field] ? true : false;
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink
          className={classes.link}
          to="/"
        >
          <img
            alt="Logo"
            className={classes.linkimg}
            height="40px"
            src="/images/logos/logo.png"
            style={{ float: 'left' }}
          />
          {/* <h1 className={classes.titulo} style={{float: 'left',
    paddingLeft: 25,
    paddingTop: 5,
    color: '#ffffff',}}> */}
          <Typography
            component="h1"
            gutterBottom
            style={{
              float: 'left',
              paddingLeft: 25,
              paddingTop: 18,
              fontSize: '2em',
              color: '#ffffff',
              textTransform:'none',
              fontWeight:'bold'
            }}
            variant="overline"
          > | Partner Manager</Typography>

          {/* </h1> */}
        </RouterLink>
        <div className={classes.flexGrow} />
        {/* <Hidden smDown> */}
        {/*<div
            className={classes.search}
            ref={searchRef}
          >

            <SearchIcon className={classes.searchIcon} />
            <Input
              className={classes.searchInput}
              disableUnderline
              onChange={handleSearchChange}
              placeholder="Search people &amp; places"
              value={searchValue}
            />

          </div>*/}
        {/* </Hidden> */}
        {/* <Hidden mdDown> */}

        {/* {(location.pathname !== "/dashboards/nuevos"
          && location.pathname !== "/dashboards/programados"
          && location.pathname !== "/dashboards/encocina"
          && location.pathname !== "/dashboards/historial"
          && location.pathname !== "/dashboards/entrega"
          && location.pathname !== "/dashboards/productos") && (
            <IconButton
              className={classes.notificationsButton}
              color="inherit"
              onClick={handleNotificationsOpen}
              ref={notificationsRef}
            >
              <Badge
                badgeContent={newNotifications.length}
                classes={{ badge: classes.notificationsBadge }}
                variant="dot"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )} */}

        <FormControlLabel
          control={<Switch
            checked={isDark}
            color="default"
            onChange={handleThemeChange}
          />}
        />

        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={handleLogout}
          style={{paddingTop: '0.85em'}}
        >
          <InputIcon className={classes.logoutIcon} />
          {t('Salir')}
          {/* {t("")} */}

        </Button>
        {/* </Hidden> */}
        {/* <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden> */}
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  staticContext: PropTypes.any
};

export default TopBar;