import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import CustomThemeProvider from 'theme/CustomThemeProvider';
import { CssBaseline } from '@material-ui/core';

ReactDOM.render(
  <CustomThemeProvider>
    <CssBaseline />
    <App />
  </CustomThemeProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
