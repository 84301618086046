import React , { useState, useEffect } from 'react';
import ReactExport from 'react-export-excel';
import { makeStyles } from '@material-ui/core/styles';
import translate from 'translate';
import { ExportButton } from 'components';
import LoadingButton from 'components/LoadingButton';
import firebase from 'utils/firebase';
import tiempo from 'utils/tiempo';
import { displayLongDate } from 'utils/timezone';

const t = translate;

const useStyles = makeStyles(() => ({
  root: {}
}));

const ReportUsers = props => {
  const { className, actualizar, ...rest } = props;
  const classes = useStyles();

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [customers, setCustomers] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [orders, setOrders] = useState([]);
  // const [customersBkp, setCustomersBkp] = useState([]);
  const [loading, setLoading] = useState(true);

  const getWalletBalance = user => {
    const wallet = wallets.find(item => item.id === user.uid);
    return wallet !== undefined ? wallet.balance : 0;
  }

  const getWalletCurrentCard = user => {
    const wallet = wallets.find(item => item.id === user.uid);
    return wallet !== undefined ? wallet.currentCard : 0;
  }

  const getWalletHolder = user => {
    const wallet = wallets.find(item => item.id === user.uid);
    return wallet !== undefined ? wallet.holder : 0;
  }

  const getLocation = user => {
    if(user.currentAddressLocation){
      const { latitude, longitude } = user.currentAddressLocation;
      return `${latitude},${longitude}`;
    }

    return '';
  }

  const getCompletedOrders = user => (
    orders.filter(order => order.userID === user.id && order.status >= 8)
  )

  const getIncompletedOrders = user => (
    orders.filter(order => order.userID === user.id && order.status < 0)
  )

  const getTotalCompletedOrders = user => {
    const userOrders = getCompletedOrders(user)
    return userOrders.length
  }

  const getFirstCompletedOrderDate = user => {
    const userOrders = getCompletedOrders(user)
    const [firstOrder] = userOrders;

    return undefined !== firstOrder ? displayLongDate(firstOrder.createdAt || firstOrder.date) : ''
  }

  const getTotalIncompletedOrders = user => {
    const userOrders = getIncompletedOrders(user)
    return userOrders.length
  }

  const getFirstIncompletedOrderDate = user => {
    const userOrders = getIncompletedOrders(user)
    const [firstOrder] = userOrders;

    return undefined !== firstOrder ? displayLongDate(firstOrder.createdAt || firstOrder.date) : ''
  }

  useEffect(() => {
    console.log({customers})
  }, [customers])

  useEffect(() => {
    const fetchCustomers2 = async () => {
      try {
        const db = firebase.firestore();
        //const refUsers = db.collection('users').orderBy('name','desc').get()
        const snapUsers = await db.collection('users').orderBy('name', 'asc').get();

        if (snapUsers.empty) {
          throw new Error('No matching documents.');
        }

        const roles = ['Administrator', 'administrator'];
        const snapWallets = await db.collection('wallets').get();
        const snapOrders = await db.collection('orders').get();

        const users = snapUsers.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));
        setCustomers(users.filter(user => -1 === roles.indexOf(user.role)))

        setWallets(snapWallets.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        })))

        setOrders(snapOrders.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        })))
        // console.log(users)
        // setCustomers(users.filter(item => item.role !== "ADMIN"));
        // setCustomersBkp(users.filter(item => item.role !== "ADMIN"));

        // setCustomers(users.filter(item => roles.indexOf(item.role) === -1 ));
        // setWallets(wal);
        setLoading(false);
      } catch(err){
        console.log('Error getting documents', err);
      }
    }

    fetchCustomers2();

  }, []);

  if(true === loading){
    return (
      <LoadingButton
        component={ExportButton}
        loader={{color:'primary'}}
        pending={loading}
      >
        Exportar {t('restaurants')}
      </LoadingButton>
    )
  }

  return (
    // <ExcelFile element={<button style={{backgroundColor:colors.blue['600'],color:"#ffffff", marginRight:"10px"}} className="MuiButtonBase-root MuiButton-root makeStyles-deleteButton-456 MuiButton-text">Exportar {t("users")}</button>} filename={`Bicci-Reporte-Usuarios`}>
    <ExcelFile
      element={
        <ExportButton className={classes.exportButton}>Exportar {t('users')}</ExportButton>
      }
      filename={'Bicci-Reporte-Usuarios'}
    >
      <ExcelSheet
        data={customers}
        name="Users"
      >
        <ExcelColumn
          label="Name"
          value="name"
        />
        <ExcelColumn
          label="Email"
          value="email"
        />
        <ExcelColumn
          label="phone"
          value="phone"
        />
        <ExcelColumn
          label="Id User"
          value="uid"
        />
        <ExcelColumn
          label="WalletBalance"
          value={getWalletBalance}
        />
        <ExcelColumn
          label="WalletCurrentCard"
          value={getWalletCurrentCard}
        />
        <ExcelColumn
          label="WalletHolder"
          value={getWalletHolder}
        />
        <ExcelColumn
          label="Evaluación"
          value={(col) => new String(col.rate ? col.rate : 0)}
        />
        <ExcelColumn
          label="Pedidos Totales"
          value={(col) => new String(col.rate ? col.rate : 0)}
        />
        <ExcelColumn
          label="CreatedAt"
          value={col => col.createdAt ? displayLongDate(col.createdAt) : ''}
        />
        <ExcelColumn
          label="Estado"
          value={(col) => new String(col.active)}
        />
        <ExcelColumn
          label="currentAddress"
          value="currentAddress"
        />
        <ExcelColumn
          label="currentAddressLocation"
          value={getLocation}
        />
        <ExcelColumn
          label="totalOrdenesCompletedas"
          value={getTotalCompletedOrders}
        />
        <ExcelColumn
          label="primeraOrdenCompleteda"
          value={getFirstCompletedOrderDate}
        />
        <ExcelColumn
          label="totalOrdenesNoCompletedas"
          value={getTotalIncompletedOrders}
        />
        <ExcelColumn
          label="primeraOrdenNoCompleteda"
          value={getFirstIncompletedOrderDate}
        />
      </ExcelSheet>
    </ExcelFile>
  );
}

export default ReportUsers;