import React from 'react';
import { apiKey } from 'config';
const { compose, withProps, lifecycle } = require('recompose');
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  google,
  DirectionsRenderer,
  Marker
} = require('react-google-maps');
const image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';

const { MarkerClusterer } = require('react-google-maps/lib/components/addons/MarkerClusterer');
const Mapa = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '300px',width:'550px' }} />,
    // containerElement: <div style={{ height: `600px`,width:`560px` }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      const DirectionsService = new window.google.maps.DirectionsService();
      const props = this.props;
      DirectionsService.route({
        origin: props.order.fromAddressLocation ? new window.google.maps.LatLng(props.order.fromAddressLocation.latitude, props.order.fromAddressLocation.longitude) : new window.google.maps.LatLng(0, 0),
        destination: props.order.toAddressLocation ? new window.google.maps.LatLng(props.order.toAddressLocation.latitude, props.order.toAddressLocation.longitude) : new window.google.maps.LatLng(0, 0),
        // origin: new window.google.maps.LatLng(latitude1, longitude1),
        // destination: new window.google.maps.LatLng(latitude2, longitude2),
        travelMode: window.google.maps.TravelMode.DRIVING,
      }, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          //alert(result);
          this.setState({
            directions: result,
          });
        } else {
          // alert(result);
          console.error(`error fetching directions ${result}`);
        }
      });
    }
  })
)(props =>
  <GoogleMap
    defaultCenter={props.order.fromAddressLocation ? new window.google.maps.LatLng(props.order.fromAddressLocation.latitude, props.order.fromAddressLocation.longitude) : new window.google.maps.LatLng(0, 0)}
    defaultZoom={17}
    //defaultCenter={new window.google.maps.LatLng(0, 0)}
  >
    {props.order.fromAddressLocation && <DirectionsRenderer directions={props.directions} />}
    <MarkerClusterer
      averageCenter
      enableRetinaIcons
      gridSize={60}
      onClick={props.onMarkerClustererClick}
    >
      <Marker
        icon={image}
        key={'marker.photo_id'}
        position={props.order.driverLocation ? { lat: props.order.driverLocation.latitude, lng: props.order.driverLocation.longitude } : {lat: 0.0, lng: 0.0}}
      />
      {/* <Marker
          icon={image}
          key={'marker.photo_id'}
          position={{ lat: props.order.driverLocation._lat, lng: props.order.driverLocation._long }}
        /> */}
    </MarkerClusterer>
  </GoogleMap>
);

export default Mapa;