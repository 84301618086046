import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');
const tiempo = {
  fechaEs: (fecha)=>{
    return new Date(fecha.seconds * 1000).toLocaleDateString('es-ES');
  },
  fechaEn: (fecha)=>{
    return new Date(fecha.seconds * 1000).toLocaleDateString('en-US');
  },
  fechayhora: (fecha)=>{
    if(fecha === undefined){
      return '';
    }
    if(fecha._seconds !== undefined){
      return moment(new Date(fecha._seconds * 1000)).format('YYYY-MM-DD hh:mm:ss');
    }
    if(fecha.seconds !== undefined){
      return moment(new Date(fecha.seconds * 1000)).format('YYYY-MM-DD hh:mm:ss');
    }

  },
  fechayhoraDos: (fecha)=>{
    if(fecha === undefined){
      return '';
    }
    if(fecha._seconds !== undefined){
      return moment(new Date(fecha._seconds * 1000)).format('YYYY-MM-DD');
    }
    if(fecha.seconds !== undefined){
      return moment(new Date(fecha.seconds * 1000)).format('YYYY-MM-DD');
    }

  },
  fecha: (fecha)=>{
    return moment(new Date(fecha.seconds * 1000)).format('DD/MM/YYYY');
  },
  fecha2: (fecha)=>{
    return moment(fecha.toDate()).format('DD/MM/YYYY hh:mmA');
  },
  fechaLarga: (fecha)=>{
    return moment(new Date(fecha.seconds * 1000)).format('DD MMMM YYYY hh:mm:ss');
  },
  fechaLarga2: (fecha)=>{
    return moment(new Date(fecha.seconds * 1000)).format('DD/MM/YYYY hh:mmA');
  },
  fechaCorta: (fecha)=>{
    if(fecha === undefined){
      return '';
    }
    if(fecha._seconds !== undefined){
      return moment(new Date(fecha._seconds * 1000)).format('DD MMMM YYYY');
    }
    if(fecha.seconds !== undefined){
      return moment(new Date(fecha.seconds * 1000)).format('DD MMMM YYYY');
    }
  },
  fechaCortaC: (fecha)=>{
    if(fecha === undefined){
      return '';
    }
    if(fecha._seconds !== undefined){
      return moment(new Date(fecha._seconds * 1000)).format('DD MM YYYY');
    }
    if(fecha.seconds !== undefined){
      return moment(new Date(fecha.seconds * 1000)).format('DD MM YYYY');
    }
  },
  hora: (fecha, minutos)=>{
    if(fecha === undefined){
      return '';
    }
    return moment(new Date(fecha.seconds * 1000)).add(minutos,'minutes').format('hh:mm');
  },
  horaSeconds: (fecha)=>{
    console.log(fecha)
    if(fecha === undefined){
      return '';
    }
    if(fecha._seconds !== undefined){
      return moment(new Date(fecha._seconds * 1000)).format('HH:mm');
    }
    if(fecha.seconds !== undefined){
      return moment(new Date(fecha.seconds * 1000)).format('HH:mm');
    }

  },
  horaCorta: (fecha)=>{
    return moment(new Date(fecha._seconds * 1000)).format('hh:mm:ss');
  },
  time: (fecha)=>{
    return moment(new Date(fecha.seconds * 1000)).format('LTS');
  }
}

export default tiempo;