import * as actionTypes from 'actions';

const initialState = {
  drivers: [],
  driversActives:[],
  restaurants: [],
  countDriverSpecial:0,
  currentDateDriver:new Date(),
  driverCurretCoord: {
    latitude: -33.455525,
    longitude: -70.641513
  },
  orders: [],
  polygons: [],
  progress_save_json: 0,
  progress_delete_json: 0,
  SelectedTab: 0
};

const mapsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DRIVER_ACTIVES:{
      const index = state.drivers.findIndex(dri => dri.id == action.payload);
      const newArray = [...state.drivers.splice(index,1,action.payload)]
      return {
        ...state,
        driversActives: newArray
      }
    }
    case actionTypes.UPDATE_CURRENTDATEDRIVER:{
      return {
        ...state,
        currentDateDriver: action.payload
      }
    }
    case actionTypes.UPDATE_COUNTDRIVERSPECIAL:{
      return {
        ...state,
        countDriverSpecial: action.payload
      }
    }
    case actionTypes.GET_DRIVER_ACTIVES:{
      return {
        ...state,
        driversActives: action.payload
      }
    }
    case actionTypes.UPDATE_DRIVERS: {
      return {
        ...state,
        drivers: action.payload
      };
    }
    case actionTypes.UPDATE_RESTAURANTS: {
      return {
        ...state,
        restaurants: action.payload
      };
    }
    case actionTypes.UPDATE_ORDERS: {
      return {
        ...state,
        orders: action.payload
      };
    }
    case actionTypes.UPDATE_DRIVER_CURRENT_COORD: {
      return {
        ...state,
        driverCurretCoord: action.payload
      };
    }
    case actionTypes.UPDATE_SELECTED_TAB: {
      return {
        ...state,
        SelectedTab: action.payload
      };
    }
    case actionTypes.UPDATE_POLYGONS: {
      return {
        ...state,
        polygons: action.payload
      };
    }
    case actionTypes.UPDATE_PROGRESSSAVEJSON: {
      return {
        ...state,
        progress_save_json: action.payload
      };
    }
    case actionTypes.UPDATE_PROGRESSDELETEJSON: {
      return {
        ...state,
        progress_delete_json: action.payload
      };
    }

    case actionTypes.ISVISIBLEPOLYGON: {
      const index = state.polygons.findIndex(poly => poly.id == action.payload); //finding index of the item
      const newArray = [...state.polygons]; //making a new array
      newArray[index].show = !newArray[index].show;
      return {
        ...state,
        polygons: newArray
      };
    }
    default: {
      return state;
    }
  }
};

export default mapsReducer;