import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { Link as RouterLink } from 'react-router-dom';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Link,
  Switch,
  Button,
  colors
} from '@material-ui/core';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import RestoreIcon from '@material-ui/icons/Restore';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import firebase from 'utils/firebase';
import EventOutlined from '@material-ui/icons/EventOutlined';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, icono, ...other } = props;

  return (
    <div
      aria-labelledby={`vertical-tab-${index}`}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    color: '#ffffff',
    //   position: 'absolute',
    //   top: '50%',
    //   left: '50%',
    //  transform: 'translate(-50%, -50%)',
    outline: 'none',
    // boxShadow: theme.shadows[3],
    marginLeft: '10px',
    width: 160,
    height: 55,
    maxHeight: '100%',
    //overflowY: 'auto',
    maxWidth: '100%',
    marginTop: '20px'
  },
  badge: {
    badge: {
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      color: '#ffffff',
      backgroundColor: '#e53935'
    },
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: '#8937E9',
    // colors.green[600],
    '&:hover': {
      backgroundColor: '#8937E9',
      // colors.green[900]
    },
    width: '100%',
    fontSize: 'bold'
  },
  rechazarButton: {
    color: theme.palette.white,
    backgroundColor: '#64D3DE',
    // colors.green[600],
    '&:hover': {
      backgroundColor: '#64D3DE',
      // colors.green[900]
    },
    width: '100%',
    fontSize: 'bold'
  }
}));

const NavBarVertical = (props) => {
  const session = useSelector(state => state.session);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [pedidos, setPedidos] = React.useState([]);
  const { open, onClose, category, titulo, link, icono, countdown, handleStatus, actualizar, setLoading, className, marcado, ...rest } = props;
  const role = session.user.role;
  const restaurantID = session.user.restaurantID;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let color = marcado ? '#573A90' : '#ffffff';
  let texto = !marcado ? '#573A90' : '#ffffff';

  useEffect(() => {
    let mounted = true;
    (async () => {
      if(session.loggedIn == true){
        // let ref = await firebase.firestore().collection('restaurants').where('admin', '==', session.user.id).get();
        // let result = await ref.docs.map(item => { return item.data() });
        // let restaurantID = result.length > 0 ? result[0].id : "";
        let doc = firebase.firestore().collection('orders').where('status', '==', 2)
          .where('placeID', '==', restaurantID);
        const observer = doc.onSnapshot(docSnapshot => {
          let data = [];
          docSnapshot.docChanges().forEach(function (change) {

            if (change.type === 'added') {
              //   console.log("New order: ", change.doc.data());
              if(change.doc.data().enCocina !== true){
                data.push(change.doc.data().id);
              }
            }
            if (change.type === 'modified') {
              //   console.log("New order: ", change.doc.data());
              if(change.doc.data().enCocina !== true){
                data.push(change.doc.data().id);
              }
            }

          });

          setPedidos(pedidos => [...pedidos].concat(data));

        }, err => {
          console.log(`Encountered error: ${err}`);
        });
      }
    })();

    return () => {
      mounted = false;
    };
  }, []);

  const Icon = (props) => {
    //     return <FastfoodIcon style={{ color: '#ffffff', fontSize: 80 }} />
    // }
    switch (props.icono) {
      case 1:
        // code block
        return (
          <Badge
            badgeContent={props.pedidos}
            color="error"
            style={{ fontSize: '28px' }}
          >
            {(props.pathname == '/dashboards/nuevos') ?
              <FastfoodIcon style={{ color: '#ffffff', fontSize: 20 }} />
              :
              <FastfoodIcon style={{ color: '#573A90', fontSize: 20 }} />
            }
          </Badge>
        )
      case 2:
        // code block
        return (
          (props.pathname == '/dashboards/encocina') ?
            <WhatshotIcon style={{ color: '#ffffff', fontSize: 20 }} />
            :
            <WhatshotIcon style={{ color: '#573A90', fontSize: 20 }} />
        )
      case 3:
        // code block
        return (
          (props.pathname == '/dashboards/entrega') ?
            <LocalMallIcon style={{ color: '#ffffff', fontSize: 20 }} />
            :
            <LocalMallIcon style={{ color: '#573A90', fontSize: 20 }} />
        )
      case 4:
        // code block
        return (
          (props.pathname == '/dashboards/historial') ?
            <RestoreIcon style={{ color: '#ffffff', fontSize: 20 }} />
            :
            <RestoreIcon style={{ color: '#573A90', fontSize: 20 }} />
        )
      case 5:
        // code block
        return (
          (props.pathname == '/dashboards/productos') ?
            <ShoppingCartIcon style={{ color: '#ffffff', fontSize: 20 }} />
            :
            <ShoppingCartIcon style={{ color: '#573A90', fontSize: 20 }} />
        )
      case 6:
        // code block
        return (
          (props.pathname == '/dashboards/programados') ?
            <EventOutlined style={{ color: '#ffffff', fontSize: 20 }} />
            :
            <EventOutlined style={{ color: '#573A90', fontSize: 20 }} />
        )
      default:
        // code block
        return <ShoppingCartIcon style={{ color: '#573A90', fontSize: 20 }} />
    }
  }



  return (
    <Link
      color="inherit"
      component={RouterLink}
      to={link}
      //to="/management/centers/1"
      variant="h6"
    >
      <div className={classes.root}>
        <Card
          {...rest}
          className={clsx(classes.root, className)}
          style={{ backgroundColor: color }}
        >
          <form>
            <CardContent>
              <Typography
                align="center"
                gutterBottom
                style={{marginTop: props.icono === 1 ? '-16px' : '-20px'}}
                variant="h5"
              >
                <Icon
                  icono={icono}
                  pathname={props.location.pathname}
                  pedidos={pedidos.length}
                />
              </Typography>
              <Typography
                align="center"
                gutterBottom
                style={{ fontSize: '14px', color: texto, marginTop: '-8px'}}
                variant="h4"
              >
                {titulo}
              </Typography>
            </CardContent>
          </form>
        </Card>
      </div>
    </Link>
  );
}


export default withRouter(NavBarVertical);