import * as actionTypes from 'actions';

const initialState = {
  products: [],
}

const menuReducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.MENU_PRODUCTS_LIST: {
      return {
        ...state,
        products: [...action.products],
      }
    }
    default: {
      return state;
    }
  }
}

export default menuReducer;
