import firebase from 'utils/firebase';
import moment from 'moment-timezone';

export const getTimezone = () => {
  const timezone = moment.tz.guess() || 'America/Santiago';
  // const timezone = 'America/Santiago';

  return timezone;
}

export const getDateFromTime = date => {
  const newDate = moment(date);
  return newDate.tz(getTimezone());
}

export const displayDateFormat = (date, format = 'HH:mm') => {
  const newDate = date instanceof firebase.firestore.Timestamp ? date.toDate() : date;
  return getDateFromTime(newDate).format(format);
}

export const displayLongDate = (date) => {
  return displayDateFormat(date, 'DD MMMM YYYY hh:mm:ss');
}

export const displayLongDate3 = (date) => {
  return displayDateFormat(date, 'DD/MM/YYYY hh:mmA');
}
