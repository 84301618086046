import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
// import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IconButton } from '@material-ui/core';
import { renderRoutes } from 'react-router-config';
import LoadingBar from 'react-redux-loading-bar'
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';

// import theme from './theme';
import { configureStore } from './store';
import routes from './routes';
import {
  ScrollReset,
  GoogleAnalytics,
  // CookiesNotification,
} from './components';
// import './mixins/chartjs';
// import './mixins/moment';
// import './mixins/validate';
// import './mixins/prismjs';
// import './mock';
import './utils/firebase';
import './assets/scss/index.scss';

const App = () => {
  const history = createBrowserHistory();
  const store = configureStore();
  const notistackRef = React.createRef();

  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }

  // console.log(store.dispatch);

  return (
    // <React.StrictMode>
    <StoreProvider store={store}>
      <LoadingBar style={{zIndex: 10, position: 'fixed'}} />
      <SnackbarProvider
        action={(key) => (
          <IconButton
            color="inherit"
            disableRipple
            onClick={onClickDismiss(key)}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        maxSnack={3}
        preventDuplicate
        ref={notistackRef}
        TransitionComponent={Slide}
        variant="success"
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <ScrollReset />
            <GoogleAnalytics />
            {/* <CookiesNotification /> */}
            {renderRoutes(routes)}
          </Router>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </StoreProvider>
    // </React.StrictMode>
  );
};

export default App;