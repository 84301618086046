import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar'

import appReducer from  './appReducer';
import sessionReducer from './sessionReducer';
import mapsReducer from  './mapsReducer';
import menuReducer from  './menuReducer';

const rootReducer = combineReducers({
  loadingBar: loadingBarReducer,
  app: appReducer,
  session: sessionReducer,
  maps: mapsReducer,
  menu: menuReducer,
});

export default rootReducer;
