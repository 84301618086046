import firebase from 'utils/firebase';
export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const SESSION_UPDATE = 'SESSION_UPDATE';

export const login = (params) => {
  return function (dispatch) {
    let email = params.email;
    let password = params.password;

    firebase.auth().signInWithEmailAndPassword(email, password).then(async item => {
      const db = firebase.firestore();

      let user = item.user;
      console.log(user);
      const rest = await db.collection('restaurants').where('admin','==',user.uid).get();
      const control = await rest.docs.map(item => {return item.data()}).filter(item => item.deleted !== true);


      let restaurantes = [];
      let restaurantID = '';
      let restaurant = {};
      let restaurantName = '';
      if(control.length > 0){
        restaurantes = control !== undefined ? control : [];
        restaurantID = control !== undefined && control.length < 2 ? control[0].id : '';
        restaurant = control !== undefined && control.length < 2 ? control[0] : '';
        restaurantName = control !== undefined && control.length < 2 ? control[0].name : '';
      }


      const referencia = await db.collection('administrators').doc(user.uid).get();
      const res = referencia.data();
      const username = res.name;
      const email = res.email;
      const phone = res.phone;

      user.role = res.role;
      user.id = referencia.id;
      user.restaurantID = restaurantID;
      user.restaurant = restaurant;
      user.restaurantes = restaurantes;
      user.restaurantName = restaurantName;
      user.username = username;

      if (user.role === '' || user.role === undefined) {
        dispatch({
          type: SESSION_LOGOUT
        });
      } else {
        localStorage.setItem('username', username);
        localStorage.setItem('email', email);
        localStorage.setItem('phone', phone);
        //localStorage.setItem('token', idToken);
        localStorage.setItem('userId', user.id);
        dispatch({
          type: SESSION_LOGIN,
          user: user
        });
      }


    }).catch(function (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log('error');
      console.log(errorMessage);
      dispatch({
        type: SESSION_LOGOUT
      });
    });
  }
}

export const logout = () => {
  return function (dispatch) {
    firebase.auth().signOut();
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    dispatch({
      type: SESSION_LOGOUT
    });
  }
}

export const update = (params) => {
  return function (dispatch) {
    dispatch({
      type: SESSION_UPDATE,
      params: params
    });
  }
}