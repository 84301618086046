import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { fetchCapabilities } from 'actions';
import { getUser } from 'selectors';

const useStyles = makeStyles((theme) => ({
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: 'solid 1px #000'
  },
  loaderIndicator: {
    marginBottom: theme.spacing(2)
  },
}));

const LoadInitialData = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = () => {
      // setLoading(true);
      const promises = [];

      promises.push(dispatch(fetchCapabilities(user.role)));
      // promises.push(dispatch(fetchCapabilities('administrator')));

      const promise = Promise.all(promises);
      promise.then(() => setLoading(false))

      return async () => {
        // const result = await promise;
        // result.forEach(r => r())
      };
    }

    const unsusbscribe = fetchData();
    return () => unsusbscribe();
  }, [user]);

  return (
    <>
      {props.children(loading)}
    </>
  );
}
export default LoadInitialData;
