import firebase from 'utils/firebase'
import reduceChanges from 'utils/reduceChanges';

export const APP_SET_CAPABILITIES = 'APP_SET_CAPABILITIES';
export const APP_UPDATE_ROLES = 'APP_UPDATE_ROLES';

// export const getUserCapabilities = () => dispatch => {

// }

export const setUserCapabilities = (payload) => ({
  type: APP_SET_CAPABILITIES,
  payload,
})

export const appUpdateRoles = payload => ({
  type: APP_UPDATE_ROLES,
  payload,
})

export const fetchCapabilities = (role) => dispatch => {
  const noExists = () => {
    dispatch(setUserCapabilities({
      manage_dashboard: true,
    }));
  }

  return new Promise((resolve, reject) => {
    const promise = firebase.firestore().collection('roles')
      .doc(role)
      // .doc('administrator')
      .get();

    promise.then(roleSnap => {
      if(roleSnap.exists){
        const role = roleSnap.data();
        // console.log({role});
        dispatch(setUserCapabilities({
          ...role.capabilities,
          manage_dashboard: true,
        }));

        resolve(promise);
      } else {
        noExists();
        resolve(promise);
      }
    }).catch(() => {
      noExists();
      resolve(promise);
    })
  })
}

export const fetchRoles = () => dispatch => {
  return new Promise((resolve, reject) => {
    let roles = [];
    const unsusbscribe = firebase.firestore().collection('roles')
      .orderBy('date', 'asc')
      .onSnapshot(snapshot => {
        resolve(unsusbscribe);
        roles = reduceChanges(roles, snapshot);
        dispatch(appUpdateRoles(roles))
      }, reject);
  })
}

export const initializeApp = () => dispatch => {
  // load global rest api
}