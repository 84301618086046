import React, { Suspense, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

import { CapabilityRoute } from 'components';
import { NavBar, TopBar, ChatBar } from '../../components';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  topBar: {
    zIndex: 2,
    position: 'relative'
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 210,
    minWidth: 190,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto'
  }
}));

const Content = props => {
  const { route } = props;

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        {/* Cocinaaaa */}
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            <Switch>
              {route.routes.map((route, i) => (
                <CapabilityRoute
                  capability={route.capability}
                  component={route.component}
                  exact={route.exact}
                  key={route.key || i}
                  path={route.path}
                  strict={route.strict}
                />
              ))}
            </Switch>
          </Suspense>
        </main>
      </div>
      {/*<ChatBar />*/}
    </div>
  );
};

Content.propTypes = {
  route: PropTypes.object
};

export default Content;
