import React , { useState, useEffect } from 'react';
import ReactExport from 'react-export-excel';
import { makeStyles } from '@material-ui/core/styles';
import firebase from 'utils/firebase';
import config from 'config';
import translate from 'translate';
import { ExportButton } from 'components';
import chunk from 'utils/chunk';
import LoadingButton from 'components/LoadingButton';

const t = translate;

const validItemQuery = (param, arr) => {
  if(
    'string' === typeof(param) &&
    '' !== param &&
    undefined === arr.find(item => item === param)
  ){
    return true;
  }

  return false;
}

const ReportRestaurants = props => {
  const { className, actualizar, ...rest } = props;

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [customers, setCustomers] = useState([]);
  const [customersBkp, setCustomersBkp] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLocation = (col) => {
    if(col.location){
      const { latitude, longitude } = col.location;
      return `${latitude},${longitude}`;
    }

    return '';
  }

  useEffect(() => {
    const fetchRestaurants = async () => {
      let posts = [];
      const db = firebase.firestore();
      try {
        //const snapRestaurants = db.collection('users').orderBy('name','desc').get()
        const snapRestaurants = await db.collection('restaurants').orderBy('name','asc').get();

        if (snapRestaurants.empty) {
          console.log('No matching documents.');
          return;
        }

        const adminList = [];
        snapRestaurants.forEach(doc => {
          const restaurant = doc.data();

          // get admin emails
          if(validItemQuery(restaurant.admin, adminList)){
            adminList.push(restaurant.admin);
          }

          posts.push(restaurant);
        });

        // get email data
        const adminsArr = [];
        const chunkAdmin = chunk(adminList);

        for(const admins of chunkAdmin){
          const snapAdmin = await db.collection('administrators').where('id', 'in', admins).get();
          snapAdmin.forEach(item => {
            adminsArr.push(item.data());
          })
        }

        // new object restaurants
        posts = posts.map(restaurant => {
          const admin = adminsArr.find(admin => restaurant.admin === admin.id);
          return {
            ...restaurant,
            adminEmail: undefined !== admin ? admin.email : '',
          }
        })

        // console.log(posts)
        setCustomers(posts);
        setCustomersBkp(posts);
        setLoading(false);
      } catch(err) {
        console.log('Error getting documents', err);
      }
    }

    fetchRestaurants();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingButton
          component={ExportButton}
          loader={{color:'primary'}}
          pending={loading}
        >
          Exportar {t('restaurants')}
        </LoadingButton>
      ) : (
        <ExcelFile
          element={<ExportButton>Exportar {t('restaurants')}</ExportButton>}
          filename="Bicci-Reporte-Restaurants"
        >
          <ExcelSheet
            data={customers}
            name="Users"
          >
            <ExcelColumn
              label="ID"
              value="id"
            />
            <ExcelColumn
              label="Name"
              value="name"
            />
            <ExcelColumn
              label="Email"
              value="email"
            />
            <ExcelColumn
              label="phone"
              value="phone"
            />
            <ExcelColumn
              label="Id User"
              value="uid"
            />
            <ExcelColumn
              label="Evaluación"
              value={(col) => new String(col.rate ? col.rate : 0)}
            />
            <ExcelColumn
              label="Pedidos Totales"
              value={(col) => new String(col.rate ? col.rate : 0)}
            />
            <ExcelColumn
              label="Estado"
              value={(col) => new String(col.active)}
            />
            <ExcelColumn
              label="Online"
              value={(col) => new String(col.offline === false)}
            />
            <ExcelColumn
              label="Ratio"
              value="ratio"
            />
            <ExcelColumn
              label="address"
              value="address"
            />
            <ExcelColumn
              label="Region"
              value="regionName"
            />
            <ExcelColumn
              label="Distrito"
              value="districtName"
            />
            <ExcelColumn
              label="Zona"
              value="zoneName"
            />
            <ExcelColumn
              label="Location"
              value={getLocation}
            />
            <ExcelColumn
              label="Categorias"
              value="categoryName"
            />
            <ExcelColumn
              label="Admin"
              value="adminEmail"
            />
            <ExcelColumn
              label="Notas"
              value="notes"
            />
          </ExcelSheet>
        </ExcelFile>
      )}
    </>
  );
}

export default ReportRestaurants;