import * as actionTypes from 'actions';

const initialState = {
  ingredients: [],
}

const menuReducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.MENU_INGREDIENTS_LIST: {
      return {
        ...state,
        ingredients: [...action.ingredients],
      }
    }
    default: {
      return state;
    }
  }
}

export default menuReducer;
