import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { getCapabilities } from 'selectors';

const CapabilityRoute = ({ component: Component, capability, ...rest }) => {
  const capabilities = useSelector(getCapabilities)

  console.log({capabilities, capability, rest})

  return (
    <Route
      {...rest}
      render={props =>
        true === capabilities[capability] ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/errors/error-500',
              state: {
                from: props.location
              }
            }}
          />
        )
      }
    />
  );
}

export default CapabilityRoute;
