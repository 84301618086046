import { createSelector } from 'reselect';

export const getApp = state => state.app;

export const getCapabilities = createSelector(
  getApp,
  app => app.capabilities
)

export const getRoles = createSelector(
  getApp,
  app => app.roles
)
