const lang = 'es';
const translate = (word) => {
  switch (word.toLowerCase()) {
    case'name': return (lang == 'es') ? 'Nombre' : 'Name';
    case'full name': return (lang == 'es') ? 'Nombre completo' : 'Full name';
    case'email': return (lang == 'es') ? 'Correo' : 'Email';
    case'email address': return (lang == 'es') ? 'Dirección de correo' : 'Email address';
    case'phone': return (lang == 'es') ? 'Telefono' : 'Phone';
    case'phone number': return (lang == 'es') ? 'Número Telefónico' : 'Phone number';
    case'address': return (lang == 'es') ? 'Dirección' : 'Address';
    case'status': return (lang == 'es') ? 'Estatus' : 'Status';
    case'customers': return (lang == 'es') ? 'Clientes' : 'Customers';
    case'users': return (lang == 'es') ? 'Usuarios' : 'Users';
    case'user': return (lang == 'es') ? 'Usuario' : 'User';
    case'deleted': return (lang == 'es') ? 'Borrado' : 'Deleted';
    case'active': return (lang == 'es') ? 'Activo' : 'Active';
    case'deactive': return (lang == 'es') ? 'Desactivar' : 'Deactive';
    case'management': return (lang == 'es') ? 'Administrar' : 'Management';
    case'role': return (lang == 'es') ? 'Rol' : 'role';
    case'language': return (lang == 'es') ? 'Lenguaje' : 'Language';
    case'summary': return (lang == 'es') ? 'Resumen' : 'Summary';
    case'view summary': return (lang == 'es') ? 'Ver Resumen' : 'View Summary';
    case'dni': return (lang == 'es') ? 'Nro Documento' : 'Dni';
    case'agree terms': return (lang == 'es') ? 'Acepto Terminos' : 'Agree Terms';
    case'disabled': return (lang == 'es') ? 'Deshabilitado' : 'Disabled';
    case'isactive': return (lang == 'es') ? 'Esta activo' : 'isActive';
    case'franchise venues': return (lang == 'es') ? 'Sucursales' : 'Franchise venues';
    case'franchise': return (lang == 'es') ? 'Franquicia' : 'Franchise';
    case'venue': return (lang == 'es') ? 'Sucursal' : 'Venue';
    case'estimated time': return (lang == 'es') ? ' tiempo estimado' : 'Estimated time';
    case'price': return (lang == 'es') ? 'Precio' : 'Price';
    case'service': return (lang == 'es') ? 'Servicio' : 'Service';
    case'pack': return (lang == 'es') ? 'Paquete' : 'Pack';
    case'time': return (lang == 'es') ? 'Tiempo' : 'Time';
    case'description': return (lang == 'es') ? 'Descripción' : 'Description';
    case'service list': return (lang == 'es') ? 'Lista de servicios' : 'Service list';
    case'service info': return (lang == 'es') ? 'Información del servicio' : 'Service info';
    case'template': return (lang == 'es') ? 'Plantilla' : 'Template';
    case'availabilities': return (lang == 'es') ? 'Disponibilidades' : 'Availabilities';
    case'venue name': return (lang == 'es') ? 'Nombre de sucursal' : 'Venue name';
    case'venues': return (lang == 'es') ? 'Sucursales' : 'Venues';
    case'venue info': return (lang == 'es') ? 'Información de la sucursal' : 'Venue info';
    case'venue list': return (lang == 'es') ? 'Lista de sucursales' : 'Venues list';
    case'sucursal name': return (lang == 'es') ? 'Nombre de sucursal' : 'Sucursal name';
    case'prowasher name': return (lang == 'es') ? 'Nombre de prowasher' : 'Prowasher name';
    case'franchise name': return (lang == 'es') ? 'Nombre de franquicia' : 'Franchise name';
    case'direction': return (lang == 'es') ? 'Dirección' : 'Direction';
    case'date': return (lang == 'es') ? 'Fecha' : 'Date';
    case'order list': return (lang == 'es') ? 'Lista de ordenes' : 'Order list';
    case'orders': return (lang == 'es') ? 'Ordenes' : 'Orders';
    case'service date': return (lang == 'es') ? 'Día del servicio' : 'Service day';
    case'card brand': return (lang == 'es') ? 'Marca del vehiculo' : 'Card brand';
    case'card category': return (lang == 'es') ? 'Categoria del vehiculo' : 'Card category';
    case'card model': return (lang == 'es') ? 'Modelo del vehiculo' : 'Card model';
    case'card patent': return (lang == 'es') ? 'Patente del vehiculo' : 'Card patent';
    case'amount': return (lang == 'es') ? 'Monto' : 'Amount';
    case'expiration date': return (lang == 'es') ? 'Fecha de expiración' : 'Expiration date';
    case'code': return (lang == 'es') ? 'Codigo' : 'Code';
    case'comment': return (lang == 'es') ? 'Comentario' : 'Comment';
    case'evaluation comment': return (lang == 'es') ? 'Comentario de evaluación' : 'Evaluation comment';
    case'evaluation rate': return (lang == 'es') ? 'Valoración de evaluación' : 'Evaluation rate';
    case'indications': return (lang == 'es') ? 'Indicaciones' : 'Indications';
    case'total price': return (lang == 'es') ? 'Precio total' : 'Total price';
    case'total time': return (lang == 'es') ? 'Tiempo total' : 'Total time';
    case'assign prowasher': return (lang == 'es') ? 'Asignar prowasher' : 'Assign prowasher';
    case'billing activity code': return (lang == 'es') ? 'Codigo de activación de facturación' : 'Billing activity code';
    case'billing address': return (lang == 'es') ? 'Dirección de facturación' : 'Billing address';
    case'billing dni': return (lang == 'es') ? 'Identificación de facturación' : 'Billing dni';
    case'billing email': return (lang == 'es') ? 'Email de facturación' : 'Billing email';
    case'billing phone': return (lang == 'es') ? 'Telefono de facturación' : 'Billing phone';
    case'billing gyre': return (lang == 'es') ? 'Giro de facturación' : 'Billing gyre';
    case'billing required': return (lang == 'es') ? 'Facturación requerida' : 'Billing required';
    case'billing reason': return (lang == 'es') ? 'Razón de la facturación' : 'Billing reason';
    case'categories': return (lang == 'es') ? 'Categorias' : 'Categories';
    case'category': return (lang == 'es') ? 'Categoría' : 'Category';
    case'categories list': return (lang == 'es') ? 'Lista de categorias' : 'Categories list';
    case'category info': return (lang == 'es') ? 'Información de categoria' : 'Category info';
    case'documents': return (lang == 'es') ? 'Documentos' : 'Documents';
    case'document': return (lang == 'es') ? 'Documento' : 'Document';
    case'documents list': return (lang == 'es') ? 'Lista de documentos' : 'Documents list';
    case'stores': return (lang == 'es') ? 'Tiendas' : 'Stores';
    case'stores list': return (lang == 'es') ? 'Lista de tiendas' : 'Stores list';
    case'store info': return (lang == 'es') ? 'Información de tienda' : 'Store info';
    case'coupon list': return (lang == 'es') ? 'Lista de cupones' : 'Coupon list';
    case'coupon info': return (lang == 'es') ? 'Información de cupones' : 'Coupon info';
    case'coupons': return (lang == 'es') ? 'cupones' : 'Coupons';
    case'coupon': return (lang == 'es') ? 'cupon' : 'Coupon';
    case'discount': return (lang == 'es') ? 'Descuento' : 'Discount';
    case'product': return (lang == 'es') ? 'Producto' : 'Product';
    case'products': return (lang == 'es') ? 'Productos' : 'Products';
    case'store': return (lang == 'es') ? 'Tienda' : 'Store';
    case'substance': return (lang == 'es') ? 'Sustancia' : 'Substance';
    case'substances': return (lang == 'es') ? 'Sustancias' : 'Substances';
    case'driver': return (lang == 'es') ? 'Conductor' : 'Driver';
    case'drivers': return (lang == 'es') ? 'Conductores' : 'Drivers';
    case'substance info': return (lang == 'es') ? 'Información de sustancia' : 'Substance info';      // acciones generales {`${t("price")} A`}

    case'sign in on the internal platform': return (lang == 'es') ? 'Iniciar sesión en la plataforma interna' : 'Sign in on the internal platform';
    case'sign in': return (lang == 'es') ? 'Iniciar Sesión' : 'Sign in';
    case'home': return (lang == 'es') ? 'Inicio' : 'Home';
    case'good morning': return (lang == 'es') ? 'Buenos días' : 'Good mornig';
    case'here’s what’s happening with your projects today': return (lang == 'es') ? 'Esto es lo que está sucediendo con tus proyectos hoy' : 'Here’s what’s happening with your projects today';
    case'see all': return (lang == 'es') ? 'Ver Todos' : 'See All';
    case'notifications': return (lang == 'es') ? 'Notificaciones' : 'Notifications';
    case'pages': return (lang == 'es') ? 'Menu' : 'Pages';
    case'search': return (lang == 'es') ? 'Buscar' : 'Search';
    case'delete': return (lang == 'es') ? 'Borrar' : 'Delete';
    case'close': return (lang == 'es') ? 'Cerrar' : 'Close';
    case'save': return (lang == 'es') ? 'Guardar' : 'Save';
    case'view': return (lang == 'es') ? 'Ver' : 'View';
    case'sign out': return (lang == 'es') ? 'Salir' : 'Sign out';
    case'export': return (lang == 'es') ? 'Exportar' : 'Export';
    case'actions': return (lang == 'es') ? 'Acciones' : 'Actions';
    case'edit': return (lang == 'es') ? 'Editar' : 'Edit';
    case'add': return (lang == 'es') ? 'Agregar' : 'Add';
    case'account': return (lang == 'es') ? 'Cuenta' : 'Account';
    case'data': return (lang == 'es') ? 'Datos' : 'Data';
    case'other actions': return (lang == 'es') ? 'Otras acciones' : 'Other actions';
    case'reset & send password': return (lang == 'es') ? 'Reiniciar y enviar contraseña' : 'Reset & send password';
    case'reset': return (lang == 'es') ? 'Reiniciar' : 'Reset';
    case'send password': return (lang == 'es') ? 'enviar contraseña' : 'send password';
    case'password': return (lang == 'es') ? 'Contraseña' : 'Password';
    case'repeat password': return (lang == 'es') ? 'Repetir Contraseña' : 'Repeat Password';
    case'disable': return (lang == 'es') ? 'Deshabilitar' : 'Disable';
    case'enable': return (lang == 'es') ? 'habilitar' : 'Enable';
    case'login as': return (lang == 'es') ? 'Loguear como' : 'Login as';
    case'export client': return (lang == 'es') ? 'Exportar Datos' : 'Export Client Data';
    case'export client data': return (lang == 'es') ? 'Exportar Datos del Cliente' : 'Export Client Data';
    default: return word
  }

}

export default translate;