import React from 'react'
import { Button, CircularProgress } from '@material-ui/core';

const LoadingButton = ({
  pending,
  loader = { color: 'inherit' },
  component: Component = Button,
  children,
  ...rest
}) => {
  return (
    <Component
      {...rest}
      disabled={pending}
    >
      {pending && <CircularProgress
        color={loader.color}
        size={25}
        style={{position: 'absolute'}}
      /> }
      <span style={{visibility: pending ? 'hidden' : 'visible'}}>{children}</span>
    </Component>
  )
}

export default LoadingButton;
