import { createTheme } from '@material-ui/core/styles'
import * as colors from '@material-ui/core/colors'

import typography from './typography';


// A custom theme for this app
const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: colors.purple[300],
      light: 'rgb(81, 91, 95)',
      dark: 'rgb(26, 35, 39)',
      contrastText: '#ffffff',
    },
    secondary: {
      main: colors.blue[500],
      light: 'rgb(255, 197, 112)',
      dark: 'rgb(200, 147, 89)',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    titleBar: {
      main: '#555555',
      contrastText: '#ffffff',
    },
    // error: {
    //   main: colors.red.A400,
    // },
    online: {
      main: colors.teal[900],
      hover: colors.teal[800]
    },
    offline: {
      main: colors.blueGrey[900],
      hover: colors.blueGrey[800]
    },
    inactive: {
      main: colors.red[900],
      hover: colors.red[800]
    },
  },
  typography,
})

export default theme