import * as actionTypes from 'actions';

const initialState = {
  loading: true,
  roles: [],
  capabilities: []
}

const appReducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.APP_UPDATE_ROLES: {
      return {
        ...state,
        roles: action.payload,
      }
    }
    case actionTypes.APP_SET_CAPABILITIES: {
      return {
        ...state,
        capabilities: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export default appReducer;
