import { combineReducers } from 'redux';

import categoryReducer from './categoryReducer';
import ingredientReducer from  './ingredientReducer';
import productReducer from  './productReducer';

const rootReducer = combineReducers({
  category: categoryReducer,
  ingredient: ingredientReducer,
  product: productReducer,
});

export default rootReducer;
