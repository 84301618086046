import firebase from 'utils/firebase';

export const MENU_CATEGORIES_LIST = 'MENU_CATEGORIES_LIST';
export const MENU_INGREDIENTS_LIST = 'MENU_INGREDIENTS_LIST';
export const MENU_PRODUCTS_LIST = 'MENU_PRODUCTS_LIST';

export const updateCategories = categories => ({
  type: MENU_CATEGORIES_LIST,
  categories,
})

export const updateIngredients = ingredients => ({
  type: MENU_INGREDIENTS_LIST,
  ingredients,
})

export const updateProducts = products => ({
  type: MENU_PRODUCTS_LIST,
  products,
})

export const fetchProducts = restaurantID => dispatch => {
  return new Promise((resolve, reject) => {
    let products = [];
    const unsusbscribe = firebase.firestore().collection('products')
      .where('restaurantID', '==', restaurantID).onSnapshot(snapshot => {
        resolve(unsusbscribe);
        products = reduceChanges(products, snapshot);
        dispatch(updateProducts(products));
      }, reject);
  })
}

export const fetchIngredients = restaurantID => dispatch => {
  return new Promise((resolve, reject) => {
    let ingredients = [];
    const unsusbscribe = firebase.firestore().collection('ingredients')
      .where('restaurantID', '==', restaurantID).onSnapshot(snapshot => {
        resolve(unsusbscribe);
        ingredients = reduceChanges(ingredients, snapshot);
        dispatch(updateIngredients(ingredients));
      }, reject);
  })
}

export const fetchCategories = restaurantID => dispatch => {
  return new Promise((resolve, reject) => {
    let categories = [];
    const unsusbscribe = firebase.firestore().collection('restaurants').doc(restaurantID)
      .collection('foodCategories').onSnapshot(snapshot => {
        resolve(unsusbscribe);
        categories = reduceChanges(categories, snapshot);
        dispatch(updateCategories(categories));
      }, reject);
  })
}

const reduceChanges = (arr = [], snapshot) => {
  snapshot.docChanges().forEach((change) => {
    if (change.type === 'added') {
      const data = change.doc.data();
      // console.log('New item: ', data);

      arr.push(data);
    }
    if (change.type === 'modified') {
      let data = change.doc.data();
      // console.log('Modified item: ', data);
      const index = arr.findIndex(item => item.id == data.id);

      arr = [
        ...arr.slice(0, index),
        data,
        ...arr.slice(index+1),
      ]
    }
    if (change.type === 'removed') {
      const data = change.doc.data();
      // console.log('Removed item: ', data);
      arr = arr.filter(item => item.id !== data.id)
    }
  });

  return arr;
}