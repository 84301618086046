import React , { useState } from 'react';
import ReactExport from 'react-export-excel';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import firebase from 'utils/firebase';
import tiempo from 'utils/tiempo';
import DeliveryStatus from 'utils/DeliveryStatus';

const getStatus2 = status => {
  let statusText = 'Cancelado';

  if(status > 0){
    if(status >= 8){
      statusText = 'Finalizado';
    } else {
      statusText = DeliveryStatus(status);
    }
  }

  return statusText;
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const ReportOrders = props => {
  const { className, actualizar, opent, ...rest } = props;
  const classes = useStyles();

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [customers, setCustomers] = useState(props.customers);
  const [customersBkp, setCustomersBkp] = useState([]);

  const fechaInicio = firebase.firestore.Timestamp.fromDate(new Date(props.selectedDate));
  const fechaFin = firebase.firestore.Timestamp.fromDate(new Date(props.selectedDateFin));

  // useEffect(() => {

  // }, []);

  // useEffect(() => {
  //   const fetchCustomers2 = () => {
  //       let posts = [];
  //       let fechaInicio = firebase.firestore.Timestamp.fromDate(new Date(props.selectedDate));
  //       let fechaFin = firebase.firestore.Timestamp.fromDate(new Date(props.selectedDateFin));
  //       const refUsers = firebase.firestore().collection('orders')
  //       .where('date','>=',fechaInicio)
  //       .where('date','<=',fechaFin)
  //       .orderBy('date', 'desc')
  //       .get()
  //       .then(snapshot => {
  //         console.log("Aquiiiii");
  //         if (snapshot.empty) {
  //           console.log('No matching documents.');
  //           return;
  //         }

  //         snapshot.forEach(doc => {
  //           console.log(doc.id, '=>', doc.data());
  //           posts.push(doc.data());
  //         });
  //         console.log(posts)
  //         setCustomers(posts);
  //         setCustomersBkp(posts);

  //       })
  //       .catch(err => {
  //         console.log('Error getting documents', err);
  //       });
  //     }

  //   //    fetchCustomers2();

  // }, []);

  //console.log(new Date(props.selectedDate) +" | "+new Date(props.selectedDateFin));
  //console.log(firebase.firestore.Timestamp.fromDate(new Date(props.selectedDate)) +' | '+firebase.firestore.Timestamp.fromDate(new Date(props.selectedDateFin)));
  console.log(props.customers);

  return (
    <ExcelFile
      element={
        opent==true ? (
          <Button
            color="primary"
            fullWidth
            style={{marginTop:'10px'}}
            type="submit"
            variant="contained"
          >
            Export Orders
          </Button>
        ):(
          'Cargando...'
        )
      }
      filename={`reporte-ordenes-${tiempo.fechayhoraDos(fechaInicio)}_${tiempo.fechayhoraDos(fechaFin)}`}
    >
      <ExcelSheet
        data={props.customers}
        name="Orders"
      >
        <ExcelColumn
          label="Id"
          value="id"
        />
        <ExcelColumn
          label="Date"
          value={col => tiempo.fecha(col.date)}
        />
        <ExcelColumn
          label="Time"
          value={col => tiempo.hora(col.date)}
        />
        <ExcelColumn
          label="Code"
          value="id"
        />
        <ExcelColumn
          label="UserID"
          value="userID"
        />
        <ExcelColumn
          label="DriverID"
          value="driverID"
        />
        <ExcelColumn
          label="CouponCode"
          value={col => col.couponCode ? new String(col.couponCode) : ''}
        />
        <ExcelColumn
          label="couponAmount"
          value={col => col.couponAmount ? new String(col.couponAmount) : '0'}
        />
        <ExcelColumn
          label="creditAmount"
          value={col => col.creditAmount ? new String(col.creditAmount) : '0'}
        />
        <ExcelColumn
          label="Username"
          value="userName"
        />
        <ExcelColumn
          label="userPhone"
          value="userPhone"
        />
        <ExcelColumn
          label="userEmail"
          value="userEmail"
        />
        <ExcelColumn
          label="DriverName"
          value="driverName"
        />
        <ExcelColumn
          label="Place Name"
          value="placeName"
        />
        <ExcelColumn
          label="Discount"
          value="discount"
        />
        <ExcelColumn
          label="serviceAmount"
          value="serviceAmount"
        />
        <ExcelColumn
          label="groceryAmount"
          value="groceryAmount"
        />
        <ExcelColumn
          label="Total Amount"
          value="totalAmount"
        />
        <ExcelColumn
          label="Driver Amount"
          value="driverAmount"
        />
        <ExcelColumn
          label="Tip"
          value="tip"
        />
        <ExcelColumn
          label="creditAmount"
          value="creditAmount"
        />
        <ExcelColumn
          label="Instructions"
          value="instructions"
        />
        <ExcelColumn
          label="Indications"
          value="indications"
        />
        <ExcelColumn
          label="FromAddress"
          value="fromAddress"
        />
        <ExcelColumn
          label="toAddress"
          value="toAddress"
        />
        <ExcelColumn
          label="status"
          value={col => DeliveryStatus(col.status)}
        />
        <ExcelColumn
          label="Status 2"
          value={col => getStatus2(col.status)}
        />
        <ExcelColumn
          label="payMethod"
          value={col => col.payMethod ? col.payMethod : 'Efectivo'}
        />
        <ExcelColumn
          label="Vertical"
          value={col => col.express ? 'Express' : 'Food'}
        />
        <ExcelColumn
          label="Contribution"
          value={col => parseFloat(col.contribution, 10)}
        />
        {/* <ExcelColumn label="centerName" value="centerName"/>
              <ExcelColumn label="companyName" value="companyName"/> */}
      </ExcelSheet>
    </ExcelFile>
  );
}

export default ReportOrders;