import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loadingBarMiddleware } from 'react-redux-loading-bar'
// import { createLogger } from 'redux-logger';

import rootReducer from 'reducers';
import { initializeApp } from 'actions';

// const loggerMiddleware = createLogger();
const appVersion = '1.0.5';

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('GLOBAL_STATE', serializedState)
  } catch (err) {
    console.log(err);
  }
}

const loadState = (currVersion) => {
  try {
    if (currVersion !== localStorage.getItem('appVersion')){
      localStorage.removeItem('GLOBAL_STATE');
      localStorage.setItem('appVersion', currVersion);
      return undefined;
    } else{
      const serializedState = localStorage.getItem('GLOBAL_STATE');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    }
  } catch (err) {
    return undefined;
  }
}

export default function configureStore(preloadedState = {}) {
  // const middlewares = [thunkMiddleware, loggerMiddleware]; // loggerMiddleware
  const middlewares = [thunkMiddleware, loadingBarMiddleware()];
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  // //const store = createStore(rootReducer, preloadedState, composedEnhancers);

  // const globalState = localStorage.getItem('GLOBAL_STATE');

  // //console.log(globalState);

  // const initialState = (globalState!=null) ? JSON.parse(globalState) : undefined;

  // preloadedState = initialState;

  // console.log('initialState: ', initialState);

  const initialState = loadState(appVersion);
  const store = createStore(rootReducer, initialState, composedEnhancers);

  store.dispatch(initializeApp());

  store.subscribe(() => {
    saveState(store.getState());
  });
  //const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}
