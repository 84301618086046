import React , { useState, useEffect } from 'react';
import ReactExport from 'react-export-excel';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import firebase from 'utils/firebase';
import { useSelector } from 'react-redux';
import { colors } from '@material-ui/core';
import config from 'config';
import translate from 'translate';
import tiempo from 'utils/tiempo';
import DeliveryStatus from 'utils/DeliveryStatus';

const t = translate;

const service = config.servicio;

const useStyles = makeStyles(() => ({
  root: {}
}));

const ReportPagos = props => {
  const { className, actualizar,orders, ...rest } = props;
  const classes = useStyles();

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [customers, setCustomers] = useState([]);
  const [customersBkp, setCustomersBkp] = useState([]);

  useEffect(() => {
    // const fetchCustomers2 = () => {
    //     let posts = [];
    //     //const refClaims = firebase.firestore().collection('users').orderBy('name','desc').get()
    //     const refClaims = firebase.firestore().collection('claims').orderBy('name','asc').get()
    //     .then(snapshot => {
    //       if (snapshot.empty) {
    //         console.log('No matching documents.');
    //         return;
    //       }

    //       snapshot.forEach(doc => {
    //       //  console.log(doc.id, '=>', doc.data());
    //         posts.push(doc.data());
    //       });
    //   //    console.log(posts)
    //       setCustomers(posts);
    //       setCustomersBkp(posts);

    //     })
    //     .catch(err => {
    //       console.log('Error getting documents', err);
    //     });
    //   }

    //  fetchCustomers2();

  }, []);


  return (
    <ExcelFile
      element={<button
        className="MuiButtonBase-root MuiButton-root makeStyles-deleteButton-456 MuiButton-text"
        style={{backgroundColor:colors.blue['600'],color:'#ffffff', marginRight: '-10px !important'}}
      >Exportar {t('pagos')}</button>}
    >
      <ExcelSheet
        data={orders}
        name="Orders"
      >
        <ExcelColumn
          label="Id"
          value="id"
        />
        <ExcelColumn
          label="Code"
          value="id"
        />
        <ExcelColumn
          label="CouponCode"
          value={col => col.couponCode ? new String(col.couponCode) : ''}
        />
        <ExcelColumn
          label="couponAmount"
          value={col => col.couponAmount ? new String(col.couponAmount) : '0'}
        />
        <ExcelColumn
          label="creditAmount"
          value={col => col.creditAmount ? new String(col.creditAmount) : '0'}
        />
        <ExcelColumn
          label="Username"
          value="userName"
        />
        <ExcelColumn
          label="userPhone"
          value="userPhone"
        />
        <ExcelColumn
          label="userEmail"
          value="userEmail"
        />
        <ExcelColumn
          label="DriverName"
          value="driverName"
        />
        <ExcelColumn
          label="Place Name"
          value="placeName"
        />
        <ExcelColumn
          label="Discount"
          value="discount"
        />
        <ExcelColumn
          label="groceryAmount"
          value="groceryAmount"
        />
        <ExcelColumn
          label="Total Amount"
          value="totalAmount"
        />
        <ExcelColumn
          label="Driver Amount"
          value="driverAmount"
        />
        <ExcelColumn
          label="Tip"
          value="tip"
        />
        <ExcelColumn
          label="creditAmount"
          value="creditAmount"
        />
        <ExcelColumn
          label="Instructions"
          value="instructions"
        />
        <ExcelColumn
          label="Indications"
          value="indications"
        />
        <ExcelColumn
          label="date"
          value={col => tiempo.fecha(col.date)}
        />
        <ExcelColumn
          label="FromAddress"
          value="fromAddress"
        />
        <ExcelColumn
          label="toAddress"
          value="toAddress"
        />
        <ExcelColumn
          label="status"
          value={col => DeliveryStatus(col.status)}
        />
        <ExcelColumn
          label="payMethod"
          value={col => col.payMethod ? col.payMethod : 'Efectivo'}
        />
        <ExcelColumn
          label="Express"
          value={col => col.express ? 'Express' : 'Food'}
        />
        <ExcelColumn
          label="centerName"
          value="centerName"
        />
        <ExcelColumn
          label="companyName"
          value="companyName"
        />
      </ExcelSheet>
    </ExcelFile>
  );
}

export default ReportPagos;