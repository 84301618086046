import { createTheme } from '@material-ui/core/styles'
import * as colors from '@material-ui/core/colors'

import typography from './typography';


// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: colors.indigo[500],
    },
    secondary: {
      main: colors.blue[500],
    },
    error: {
      main: colors.red.A400,
    },
    background: {
      default: '#F4F6F8',
    },
    titleBar: {
      main: '#eeeeee',
      contrastText: '#222222',
    },
    // tabs: {
    //   main: colors.red[600],
    // },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
      link: colors.blue[600]
    },
    link: colors.blue[800],
    icon: colors.blueGrey[600],
    // divider: colors.grey[200],
    online: {
      main: colors.green[100],
      hover: colors.green[200]
    },
    offline: {
      main: colors.grey[100],
      hover: colors.grey[200]
    },
    inactive: {
      main: colors.red[100],
      hover: colors.red[200]
    },
  },

  typography
})

export default theme
