import React from 'react';
import { apiKey } from 'config';
import { compose, withProps, lifecycle } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  google,
  DirectionsRenderer,
  Marker
} from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
// const markerImage = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
import markerIcon from './assets/r.png';

const MapaRestaurant = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '300px',width:'1234px' }} />,
    // containerElement: <div style={{ height: `600px`,width:`560px` }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      const DirectionsService = new window.google.maps.DirectionsService();
      DirectionsService.route({
        origin: new window.google.maps.LatLng(this.props.order.location._lat, this.props.order.location._long),
        destination: new window.google.maps.LatLng(this.props.order.location._lat, this.props.order.location._long),
        // origin: new window.google.maps.LatLng(latitude1, longitude1),
        // destination: new window.google.maps.LatLng(latitude2, longitude2),
        travelMode: window.google.maps.TravelMode.DRIVING,
      }, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          //alert(result);
          this.setState({
            directions: result,
          });
        } else {
        //  alert(result);
          console.error(`error fetching directions ${result}`);
        }
      });
    }
  })
)(props =>
  <GoogleMap
    defaultCenter={new window.google.maps.LatLng(props.order.location._lat, props.order.location._long)}
    defaultZoom={17}
    //defaultCenter={new window.google.maps.LatLng(0, 0)}
  >
    {props.order.fromAddress && <DirectionsRenderer directions={props.directions} />}
    <MarkerClusterer
      averageCenter
      enableRetinaIcons
      gridSize={60}
      onClick={props.onMarkerClustererClick}
    >
      <Marker
        icon={{
          url: markerIcon,
          scaledSize: new window.google.maps.Size(45, 45),
          // origin: new window.google.maps.Point(0,0),
          // anchor: new window.google.maps.Point(0, 0),
        }}
        key={'marker.photo_id'}
        position={{ lat: props.order.location._lat, lng: props.order.location._long }}
      />
      {/* <Marker
          icon={markerImage}
          key={'marker.photo_id'}
          position={{ lat: props.order.driverLocation._lat, lng: props.order.driverLocation._long }}
        /> */}
    </MarkerClusterer>
  </GoogleMap>
);

export default MapaRestaurant;