import * as actionTypes from 'actions';

const initialState = {
  categories: [],
}

const menuReducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.MENU_CATEGORIES_LIST: {
      return {
        ...state,
        categories: [...action.categories],
      }
    }
    default: {
      return state;
    }
  }
}

export default menuReducer;
