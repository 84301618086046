import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

// layouts
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';

// import DashboardAnalyticsView from lazy(() => import('./views/DashboardAnalytics'));
// import RateManagement from lazy(() => import('./views/RateManagement'));

const routes = [
  {
    path: '/',
    exact: true,
    // component: ()=> <Redirect to='/dashboards/nuevos'/>
    //component: () => <Redirect to="/overview" />
    component: () => <Redirect to="/dashboards/default" />

  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: '/auth/next',
        exact: true,
        component: lazy(() => import('views/Next'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/calendar',
        exact: true,
        component: lazy(() => import('views/Calendar'))
      },
      {
        path: '/changelog',
        exact: true,
        component: lazy(() => import('views/Changelog'))
      },
      {
        path: '/chat',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/chat/:id',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      // {
      //   path: '/dashboards/analytics',
      //   exact: true,
      //   component: DashboardAnalyticsView
      // },
      {
        path: '/dashboards/reportes',
        exact: true,
        component: lazy(() => import('./views/Reportes'))
      },
      {
        path: '/dashboards/kitchen',
        exact: true,
        component: lazy(() => import('./views/DashboardKitchen'))
      },
      {
        path: '/valoraciones',
        exact: true,
        //component : RateManagement
        component: lazy(() => import('views/RateManagement'))
      },
      {
        path: '/dashboards/default',
        exact: true,
        capability: 'manage_dashboard',
        //component: KitchenNuevosPedidos // COCINA
        component: lazy(() => import('./views/DashboardDefault')) // ADMIN
        //component: lazy(() => import('views/CustomerManagementList'))
      },
      {
        path: '/invoices/:id',
        exact: true,
        component: lazy(() => import('views/InvoiceDetails'))
      },
      {
        path: '/kanban-board',
        exact: true,
        component: lazy(() => import('views/KanbanBoard'))
      },
      {
        path: '/mail',
        exact: true,
        component: lazy(() => import('views/Mail'))
      },
      {
        path: '/management/customers',
        exact: true,
        capability: 'manage_users',
        component: lazy(() => import('views/CustomerManagementList'))
      },
      {
        path: '/management/customers/:id',
        exact: true,
        capability: 'manage_users',
        component: lazy(() => import('views/CustomerManagementDetails'))
      },
      {
        path: '/management/customers/:id/:tab',
        exact: true,
        capability: 'manage_users',
        component: lazy(() => import('views/CustomerManagementDetails'))
      },
      {
        path: '/valoraciones/:tab',
        exact: true,
        component: lazy(() => import('views/RateManagement'))
      },
      {
        path: '/management/drivers',
        exact: true,
        capability: 'manage_drivers',
        component: lazy(() => import('views/DriverManagementList'))
      },
      {
        path: '/management/drivers/:id',
        exact: true,
        capability: 'manage_drivers',
        component: lazy(() => import('views/DriverManagementDetails'))
      },
      {
        path: '/management/drivers/:id/:tab',
        exact: true,
        capability: 'manage_drivers',
        component: lazy(() => import('views/DriverManagementDetails'))
      },
      {
        path: '/management/restaurants',
        exact: true,
        capability: 'manage_restaurants',
        component: lazy(() => import('views/RestaurantManagementList'))
      },
      {
        path: '/management/restaurants/:id',
        exact: true,
        capability: 'manage_restaurants',
        component: lazy(() => import('views/RestaurantManagementDetails'))
      },
      {
        path: '/management/restaurants/:id/:tab',
        exact: true,
        capability: 'manage_restaurants',
        component: lazy(() => import('views/RestaurantManagementDetails'))
      },
      {
        path: '/management/administrators',
        exact: true,
        capability: 'manage_admins',
        component: lazy(() => import('views/AdministratorManagementList'))
      },
      {
        path: '/management/administrators/:id',
        exact: true,
        capability: 'manage_admins',
        component: lazy(() => import('views/AdministratorManagementDetails'))
      },
      {
        path: '/management/administrators/:id/:tab',
        exact: true,
        capability: 'manage_admins',
        component: lazy(() => import('views/AdministratorManagementDetails'))
      },
      {
        path: '/management/roles',
        exact: true,
        capability: 'manage_roles',
        component: lazy(() => import('views/RoleManagementList'))
      },
      {
        path: '/management/categories',
        exact: true,
        capability: 'manage_categories',
        component: lazy(() => import('views/CategoryManagementList'))
      },
      {
        path: '/management/categories/:id',
        exact: true,
        capability: 'manage_categories',
        component: lazy(() => import('views/CategoryManagementDetails'))
      },
      {
        path: '/management/categories/:id/:tab',
        exact: true,
        capability: 'manage_categories',
        component: lazy(() => import('views/CategoryManagementDetails'))
      },
      {
        path: '/management/kinds',
        exact: true,
        component: lazy(() => import('views/KindManagementList'))
      },
      {
        path: '/management/kinds/:id/:idrestaurant',
        exact: true,
        component: lazy(() => import('views/KindManagementDetails'))
      },
      {
        path: '/management/kinds/:id/:idrestaurant/:tab',
        exact: true,
        component: lazy(() => import('views/KindManagementDetails'))
      },
      {
        path: '/management/ingredients',
        exact: true,
        component: lazy(() => import('views/IngredientManagementList'))
      },
      {
        path: '/management/ingredients/:id/:idrestaurant',
        exact: true,
        component: lazy(() => import('views/IngredientManagementDetails'))
      },
      {
        path: '/management/ingredients/:id/:idrestaurant/:tab',
        exact: true,
        component: lazy(() => import('views/IngredientManagementDetails'))
      },
      {
        path: '/management/companies',
        exact: true,
        capability: 'manage_companies',
        component: lazy(() => import('views/CompanyManagementList'))
      },
      {
        path: '/management/companies/:id',
        exact: true,
        capability: 'manage_companies',
        component: lazy(() => import('views/CompanyManagementDetails'))
      },
      {
        path: '/management/companies/:id/:tab',
        exact: true,
        capability: 'manage_companies',
        component: lazy(() => import('views/CompanyManagementDetails'))
      },
      {
        path: '/management/products',
        exact: true,
        capability: 'manage_restaurants',
        component: lazy(() => import('views/ProductManagementList'))
      },
      {
        path: '/management/products/:id/:idrestaurant',
        exact: true,
        capability: 'manage_restaurants',
        component: lazy(() => import('views/ProductManagementDetails'))
      },
      {
        path: '/management/products/:id/:idrestaurant/:tab',
        exact: true,
        capability: 'manage_restaurants',
        component: lazy(() => import('views/ProductManagementDetails'))
      },
      {
        path: '/management/kitchens',
        exact: true,
        component: lazy(() => import('views/KitchenManagementList'))
      },
      {
        path: '/management/kitchens/:id/:idrestaurant',
        exact: true,
        component: lazy(() => import('views/KitchenManagementDetails'))
      },
      {
        path: '/management/kitchens/:id/:idrestaurant/:tab',
        exact: true,
        component: lazy(() => import('views/KitchenManagementDetails'))
      },
      {
        path: '/management/documents',
        exact: true,
        component: lazy(() => import('views/DocumentManagementList'))
      },
      {
        path: '/management/documents/:id',
        exact: true,
        component: lazy(() => import('views/DocumentManagementDetails'))
      },
      {
        path: '/management/documents/:id/:tab',
        exact: true,
        component: lazy(() => import('views/DocumentManagementDetails'))
      },
      {
        path: '/management/employees',
        exact: true,
        component: lazy(() => import('views/EmployeeManagementList'))
      },
      {
        path: '/management/employees/:id',
        exact: true,
        component: lazy(() => import('views/EmployeeManagementDetails'))
      },
      {
        path: '/management/employees/:id/:tab',
        exact: true,
        component: lazy(() => import('views/EmployeeManagementDetails'))
      },
      {
        path: '/management/centers',
        exact: true,
        component: lazy(() => import('views/CenterManagementList'))
      },
      {
        path: '/management/centers/:id',
        exact: true,
        component: lazy(() => import('views/CenterManagementDetails'))
      },
      {
        path: '/management/centers/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CenterManagementDetails'))
      },
      {
        path: '/management/services',
        exact: true,
        component: lazy(() => import('views/ServiceManagementList'))
      },
      {
        path: '/management/services/:id',
        exact: true,
        component: lazy(() => import('views/ServiceManagementDetails'))
      },
      {
        path: '/management/services/:id/:tab',
        exact: true,
        component: lazy(() => import('views/ServiceManagementDetails'))
      },
      {
        path: '/management/stores',
        exact: true,
        component: lazy(() => import('views/StoreManagementList'))
      },
      {
        path: '/management/stores/:id',
        exact: true,
        component: lazy(() => import('views/StoreManagementDetails'))
      },
      {
        path: '/management/stores/:id/:tab',
        exact: true,
        component: lazy(() => import('views/StoreManagementDetails'))
      },
      {
        path: '/management/venues',
        exact: true,
        component: lazy(() => import('views/VenueManagementList'))
      },
      /*{
        path: '/management/venues/:id',
        exact: true,
        component: lazy(() => import('views/VenueManagementDetails'))
      },*/
      // {
      //   path: '/management/venues/:id/:idfranchise',
      //   exact: true,
      //   component: lazy(() => import('views/VenueManagementDetails'))
      // },
      // {
      //   path: '/management/venues/:id/:idfranchise/:tab',
      //   exact: true,
      //   component: lazy(() => import('views/VenueManagementDetails'))
      // },
      {
        path: '/management/vehicles',
        exact: true,
        component: lazy(() => import('views/VehicleManagementList'))
      },
      {
        path: '/management/vehicles/:id',
        exact: true,
        component: lazy(() => import('views/VehicleManagementDetails'))
      },
      {
        path: '/management/vehicles/:id/:tab',
        exact: true,
        component: lazy(() => import('views/VehicleManagementDetails'))
      },
      {
        path: '/management/requests',
        exact: true,
        component: lazy(() => import('views/RequestManagementList'))
      },
      {
        path: '/management/requests/:id',
        exact: true,
        component: lazy(() => import('views/RequestManagementDetails'))
      },
      {
        path: '/management/requests/:id/:tab',
        exact: true,
        component: lazy(() => import('views/RequestManagementDetails'))
      },
      {
        path: '/management/coupons',
        exact: true,
        capability: 'manage_coupons',
        component: lazy(() => import('views/CouponManagementList'))
      },
      {
        path: '/management/coupons/:id',
        exact: true,
        capability: 'manage_coupons',
        component: lazy(() => import('views/CouponManagementDetails'))
      },
      {
        path: '/management/coupons/:id/:tab',
        exact: true,
        capability: 'manage_coupons',
        component: lazy(() => import('views/CouponManagementDetails'))
      },
      {
        path: '/management/owners',
        exact: true,
        component: lazy(() => import('views/OwnerManagementList'))
      },
      {
        path: '/management/owners/:id',
        exact: true,
        component: lazy(() => import('views/OwnerManagementDetails'))
      },
      {
        path: '/management/owners/:id/:tab',
        exact: true,
        component: lazy(() => import('views/OwnerManagementDetails'))
      },
      {
        path: '/management/rates',
        exact: true,
        component: lazy(() => import('views/RateManagementDetails'))
      },
      {
        path: '/management/rates/:id',
        exact: true,
        component: lazy(() => import('views/RateManagementDetails'))
      },
      {
        path: '/management/rates/:id/:tab',
        exact: true,
        component: lazy(() => import('views/RateManagementDetails'))
      },
      {
        path: '/management/projects',
        exact: true,
        component: lazy(() => import('views/ProjectManagementList'))
      },
      {
        path: '/management/template',
        exact: true,
        component: lazy(() => import('views/TemplateManagement'))
      },
      {
        path: '/management/orders',
        exact: true,
        capability: 'manage_orders',
        component: lazy(() => import('views/OrderManagementList'))
      },
      {
        path: '/management/orders/:id',
        exact: true,
        capability: 'manage_orders',
        component: lazy(() => import('views/OrderManagementDetails'))
      },
      {
        path: '/management/orders/:id/:tab',
        exact: true,
        capability: 'manage_orders',
        component: lazy(() => import('views/OrderManagementDetails'))
      },
      {
        path: '/management/maps',
        exact: true,
        capability: 'manage_maps',
        component: lazy(() => import('views/Maps'))
      },
      {
        path: '/overview',
        exact: true,
        capability: 'manage_dashboard',
        component: lazy(() => import('./views/Overview'))
      },
      {
        path: '/marketing',
        exact: true,
        component: lazy(() => import('views/Marketing'))
      },
      {
        path: '/help',
        exact: true,
        component: lazy(() => import('views/Help'))
      },
      {
        path: '/presentation',
        exact: true,
        component: lazy(() => import('./views/Presentation'))
      },
      {
        path: '/profile/:id',
        exact: true,
        component: lazy(() => import('views/Profile'))
      },
      {
        path: '/profile/:id/:tab',
        exact: true,
        component: lazy(() => import('views/Profile'))
      },
      {
        path: '/projects/create',
        exact: true,
        capability: 'manage_notifications',
        component: lazy(() => import('views/ProjectCreate'))
      },
      {
        path: '/management/claims',
        exact: true,
        capability: 'manage_messages',
        component: lazy(() => import('views/ClaimManagementList'))
      },
      {
        path: '/management/claims/:id',
        exact: true,
        capability: 'manage_messages',
        component: lazy(() => import('views/ClaimManagementDetails'))
      },
      {
        path: '/management/claims/:id/:tab',
        exact: true,
        capability: 'manage_messages',
        component: lazy(() => import('views/ClaimManagementDetails'))
      },
      {
        path: '/management/promotions',
        exact: true,
        capability: 'manage_promotions',
        component: lazy(() => import('views/PromotionManagementList'))
      },
      {
        path: '/management/promotions/:id',
        exact: true,
        capability: 'manage_promotions',
        component: lazy(() => import('views/PromotionManagementDetails'))
      },
      {
        path: '/management/promotions/:id/:tab',
        exact: true,
        capability: 'manage_promotions',
        component: lazy(() => import('views/PromotionManagementDetails'))
      },
      {
        path: '/management/banners',
        exact: true,
        capability: 'manage_banners',
        component: lazy(() => import('views/BannerManagementList'))
      },
      {
        path: '/management/banners/:id',
        exact: true,
        capability: 'manage_banners',
        component: lazy(() => import('views/BannerManagementDetails'))
      },
      {
        path: '/management/banners/:id/:tab',
        exact: true,
        capability: 'manage_banners',
        component: lazy(() => import('views/BannerManagementDetails'))
      },
      {
        path: '/management/bannersrider',
        exact: true,
        capability: 'manage_driver_banners',
        component: lazy(() => import('views/BannerRiderManagementList'))
      },
      {
        path: '/management/bannersrider/:id',
        exact: true,
        capability: 'manage_driver_banners',
        component: lazy(() => import('views/BannerRiderManagementDetails'))
      },
      {
        path: '/management/bannersrider/:id/:tab',
        exact: true,
        capability: 'manage_driver_banners',
        component: lazy(() => import('views/BannerRiderManagementDetails'))
      },
      {
        path: '/management/featureds',
        exact: true,
        capability: 'manage_populars',
        component: lazy(() => import('views/FeaturedManagementList'))
      },
      {
        path: '/management/featureds/:id',
        exact: true,
        capability: 'manage_populars',
        component: lazy(() => import('views/FeaturedManagementDetails'))
      },
      {
        path: '/management/featureds/:id/:tab',
        exact: true,
        capability: 'manage_populars',
        component: lazy(() => import('views/FeaturedManagementDetails'))
      },
      // {
      //   path: '/projects/:id',
      //   exact: true,
      //   component: lazy(() => import('views/CenterDetails'))
      // },
      // {
      //   path: '/projects/:id/:tab',
      //   exact: true,
      //   component: lazy(() => import('views/CenterDetails'))
      // },
      {
        path: '/projects',
        exact: true,
        component: lazy(() => import('views/ProjectList'))
      },
      {
        path: '/schedules',
        exact: true,
        component: lazy(() => import('views/ScheduleManagement'))
      },
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        path: '/settingsreportes',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsReportes'))
      },
      {
        path: '/settingsreportes/:tab',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsReportes'))
      },
      {
        path: '/settingshorarios',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsHorarios'))
      },
      {
        path: '/settingshorarios/:tab',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsHorarios'))
      },
      {
        path: '/settingsmarketing',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsMarketing'))
      },
      {
        path: '/settingsmarketing/:tab',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsMarketing'))
      },
      {
        path: '/settingsayuda',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsAyuda'))
      },
      {
        path: '/settingsayuda/:tab',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsAyuda'))
      },
      {
        path: '/settingsvaloraciones',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsValoraciones'))
      },
      {
        path: '/settingsvaloraciones/:tab',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsValoraciones'))
      },
      {
        path: '/settingsrestaurant',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsRestaurant'))
      },
      {
        path: '/settingsrestaurant/:tab',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsRestaurant'))
      },
      {
        path: '/settingsmenu',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsMenu'))
      },
      // {
      //   path: '/testingmenu/:tab',
      //   exact: true,
      //   component: lazy(() => import('views/TestingMenu'))
      // },
      // {
      //   path: '/settingsmenu/:tab',
      //   exact: true,
      //   component: lazy(() => import('views/SettingsMenu'))
      // },
      {
        path: '/settingsmenu/:tab',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/TestingMenu'))
      },
      {
        path: '/settingspagos',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsPagos'))
      },
      {
        path: '/settingspagos/:tab',
        exact: true,
        capability: 'manage_admin_restaurants',
        component: lazy(() => import('views/SettingsPagos'))
      },
      {
        path: '/social-feed',
        exact: true,
        component: lazy(() => import('views/SocialFeed'))
      },
      {
        path: '/getting-started',
        exact: true,
        component: lazy(() => import('views/GettingStarted'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
