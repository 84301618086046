import { createSelector } from 'reselect';

export const getSession = state => state.session;

export const getUser = createSelector(
  getSession,
  session => session.user
)

export const getUserId = createSelector(
  getUser,
  user => user.id
)
