const reduceChanges = (arr = [], snapshot) => {
  snapshot.docChanges().forEach((change) => {
    const data = {
      ...change.doc.data(),
      id: change.doc.id,
    };

    if (change.type === 'added') {
      // console.log('New item: ', data);

      arr = [
        ...arr,
        data,
      ]
    }
    if (change.type === 'modified') {
      // console.log('Modified item: ', data);
      const index = arr.findIndex(item => item.id === data.id);

      arr = [
        ...arr.slice(0, index),
        data,
        ...arr.slice(index+1),
      ]
    }
    if (change.type === 'removed') {
      // console.log('Removed item: ', data);
      arr = arr.filter(item => item.id !== data.id)
    }
  });

  return arr;
}

export default reduceChanges;
