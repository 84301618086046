import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Typography, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#573A90'
  },
  linkimg: {
    display: 'block',
    float: 'left'
  },
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  function MaysPrimera(string){
    return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      //color="primary"
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            className={classes.linkimg}
            //src="/images/logos/logo--white.svg"
            height="40px"
            src="/images/logos/logo.png"
          />
          <Typography
            component="h1"
            gutterBottom
            style={{
              float: 'left',
              paddingLeft: 25,
              paddingTop: 18,
              fontSize: '2em',
              color: '#ffffff',
              textTransform:'none',
              fontWeight:'bold'
            }}
            variant="overline"
          >
              | {MaysPrimera('Partner Manager')}
            {/* | {MaysPrimera('Pos')} */}
          </Typography>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
