import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { colors } from '@material-ui/core';

const ExportButton = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.getContrastText(colors.blue[600]),
    backgroundColor: colors.blue[600],
    '&:hover': {
      backgroundColor: colors.blue[800],
    },
  },
}))(Button);

export default ExportButton;